import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route, useLocation } from 'react-router-dom';
import './Dashboard/Dashboard.css';
import StudentList from './Dashboard/student_list'; // Ensure this is imported correctly
import TeacherList from './Dashboard/TeacherList'
import Overview from './Dashboard/Overview';
import LOGO from "./images/logo.jpg"
import data from '../../src/components/assets/data.json';
import Calendar from './Dashboard/Calendar'
import Attendance from './Dashboard/Attendance';
import FeesManagement from './Dashboard/FeesManagement';
import axios from 'axios';
import ResultOverview from './Dashboard/ResultOverview'
import Enquiry from './Dashboard/Enquiry'
import { Modal, Button, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
const Dashboard = () => {
    const items = ['Overview', 'Student', 'Teacher', 'Attendance', 'Fees', 'Results', 'Enquiry', 'Calendar'];
    const sessionKey = encodeURIComponent(localStorage.getItem('session_key'));
    const sessionUser = localStorage.getItem('session_user');
    const school_id = localStorage.getItem('school_id');

    const [showChangePasswordPopup, setShowChangePasswordPopup] = useState(false);
    const [activeItem, setActiveItem] = useState('overview');
    const location = useLocation();
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 992); // Mobile if width is less than 768px
    const [showProfilePopup, setShowProfilePopup] = useState(false); // Popup state

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [API_PARENT_LINK, setApiParent] = useState('');
    useEffect(() => {
        setApiParent(data.API_PARENT_LINK);
    }, []);

    const handleLogout = () => {
        // Clear session data from localStorage
        localStorage.removeItem('session_key');
        localStorage.removeItem('session_user');
        localStorage.removeItem('school_id');

        // Optionally clear the API parent link if needed
        setApiParent('');


        // Redirect to the login page
        navigate('/login');  // Replace '/login' with the correct login route in your app


        // Optionally show a message (You can replace this with a toast notification)
        //alert('You have been logged out successfully!');
    };

    const handleUpdatePassword = async (e) => {
        e.preventDefault();
        const school_id = localStorage.getItem('school_id');
        if (newPassword !== confirmPassword) {
            setErrorMessage('New Password and Confirm Password do not match.');

            return;
        } else {

            setErrorMessage('');
        }


        try {
            const payload = {
                regid: sessionUser,  // assuming `sessionUser` contains regid
                old_password: oldPassword,
                new_password: newPassword,
                confirm_new_password: confirmPassword
            };
            if (!sessionKey || sessionKey === 'null' || !sessionUser) {
                alert("Session key or user is missing. Please log in again.");
                return;
            }
            if (!API_PARENT_LINK) {
                alert("API endpoint not available");
                return;
            }

            const apiUrl = `${API_PARENT_LINK}Students/change_password/?session_user=${sessionUser}&session_key=${sessionKey}&school_id=${school_id}&device_id=Web`;


            const response = await axios.post(apiUrl, payload);
            if (response.data.success) {
                setErrorMessage('');
                toast.success('Password updated successfully!');
                closeChangePasswordPopup();
                handleLogout();
            } else {
                toast.error(response.data.message || 'Failed to update password.');
            }
        } catch (error) {
            setErrorMessage('An error occurred while updating the password.');
        }
    };

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 992);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        const currentPath = location.pathname.split('/').pop();

        if (currentPath === 'dashboard') {
            setActiveItem('Overview'); // If the path is '/dashboard' 
            navigate(`/dashboard/overview`);
        } else {
            const currentItem = currentPath.charAt(0).toUpperCase() + currentPath.slice(1);
            setActiveItem(currentItem);
        }
    }, [location]);

    const handleItemClick = (item) => {
        setActiveItem(item);
        navigate(`/dashboard/${item.toLowerCase()}`);
    };

    const toggleProfilePopup = () => setShowProfilePopup(!showProfilePopup);
    const toggleChangePasswordPopup = () => {
        setShowProfilePopup(false); // Close profile popup
        setShowChangePasswordPopup(true); // Open change password popup
    };
    const closeChangePasswordPopup = () => {
        setShowChangePasswordPopup(false);
        // Clear password fields when the modal is closed
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setErrorMessage(''); // Clear error message
    };


    return (
        <>
            <div className="dashboard-container" style={{ display: 'flex' }} >
                {/* Conditionally render the sidebar or top navbar based on screen size */}
                {isMobile ? (
                    // Top Navbar for Mobile
                    <div className="dashboard-topnav" style={{ backgroundColor: '#1A1469', padding: '10px 20px', color: 'white', position: 'fixed', width: '100%', top: 0, zIndex: 1000 }}>
                        <div style={{ display: 'flex', alignItems: 'left', marginBottom: '10px' }}>
                            <div onClick={toggleProfilePopup} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                <img src={LOGO} alt="Logo" className="rounded-circle" style={{ height: '40px', width: '40px', marginRight: '10px' }} />
                                <h5 style={{ margin: 0, color: "#f6d731" }}>The Morning Bells International School</h5>
                            </div>
                        </div>
                        <div style={{ display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap', justifyContent: 'left', marginLeft: '0px' }}>
                            {items.map((item, index) => (
                                <button key={index} onClick={() => handleItemClick(item)} style={{
                                    backgroundColor: activeItem === item ? '#2A1D89' : 'transparent',
                                    color: 'white',
                                    border: 'none',
                                    padding: '5px',
                                    cursor: 'pointer',
                                    margin: '0 2px',
                                    borderRadius: '5px',
                                    fontSize: 10,
                                    fontWeight: 'normal'
                                }}>
                                    {item}
                                </button>
                            ))}
                        </div>

                    </div>
                ) : (
                    // Sidebar for Larger Screens
                    <div className="dashboard-sidebar" style={{
                        backgroundColor: '#1A1469',
                        width: '15%',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        padding: '15px',
                        color: 'white',
                        overflowY: 'auto'
                    }}>
                        <div onClick={toggleProfilePopup} style={{ display: 'flex', alignItems: 'center', marginBottom: '60px', cursor: 'pointer' }}>
                            <img src={LOGO} alt="" className="rounded-circle" style={{ height: '40px', width: 'auto', marginRight: '5px' }} />
                            <h3 style={{ margin: 0, color: "#f6d731" }}>The Morning Bells International School</h3>
                        </div>
                        <ul>
                            {items.map((item, index) => (
                                <li key={index} className={activeItem === item ? 'active' : ''} onClick={() => handleItemClick(item)} style={{ backgroundColor: '#1A1469' }} >
                                    <label className="tab-label">{item}</label>
                                </li>
                            ))}
                        </ul>
                        
                    </div>)}

                    <div className="watermark" style={{
                            position: 'fixed',
                            bottom: '10px',
                            left: '10px',
                            fontSize: '12px',
                            textAlign: 'center',
                            color: 'white',
                            zIndex: 9999,
                            //backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional for better visibility
                            padding: '0px 0px',
                            //borderRadius: '5px',
                            pointerEvents: 'none',
                        }}>
                            <p className='m-0 p-0' >© <span>Copyright</span> <strong className="sitename">TheVectorX </strong></p>
                            {/* <p className='m-0 p-0'> <strong className="sitename">TheVectorX </strong> </p> */}
                            <p className='m-0 p-0'style={{fontSize: '10px',}}> <span>All Rights Reserved</span></p>


                            {/* <div className="credits m-0 p-0">
                                Designed by <a href="" style={{ color: 'white' }}><strong>TheVectorX</strong></a>
                            </div> */}
                        </div>

                {/* Main Content Area */}
                <div className="dashboard-content"
                    style={{
                        marginLeft: isMobile ? '0' : '15%',
                        paddingTop: isMobile ? '80px' : '20px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        width: isMobile ? '100%' : '85%',
                        overflowY: 'auto',
                        height: '100vh'
                    }}
                >
                    <Routes>
                        {items.map((item, index) => (
                            <Route key={index} path={`${item.toLowerCase()}`} element={<ContentRenderer item={item} />} />
                        ))}
                    </Routes>
                </div>

                {/* Profile Popup */}
                <Modal show={showProfilePopup} onHide={toggleProfilePopup} centered>
                    <Modal.Header closeButton>


                        <Modal.Title>
                            <img src={LOGO} alt="Logo" className="rounded-circle" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                            The Morning Bells International School
                        </Modal.Title>
                        <button
                            type="button"
                            onClick={toggleProfilePopup}
                            className="btn btn-link text-dark position-absolute"
                            style={{ top: '10px', right: '10px', padding: 0 }}
                            aria-label="Close"
                        >
                            <i className="bi bi-x-lg" style={{ fontSize: '24px' }}></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <p><strong>Welcome,</strong> {sessionUser}</p>
                        <p>
                            <i className="bi bi-person-badge-fill" style={{ fontSize: '20px', marginRight: '8px', color: '#1A1469' }}></i>
                            Admin
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={toggleChangePasswordPopup}>Change Password</Button>
                        <Button variant="danger" onClick={handleLogout}>Logout</Button>
                    </Modal.Footer>
                </Modal>
                {/* Change Password Popup */}
                <Modal show={showChangePasswordPopup} onHide={closeChangePasswordPopup} centered>
                    <Modal.Header >
                        <Modal.Title>Change Your Password</Modal.Title>
                        <button
                            type="button"
                            onClick={closeChangePasswordPopup}
                            className="btn btn-link text-dark position-absolute"
                            style={{ top: '10px', right: '10px', padding: 0 }}
                            aria-label="Close"
                        >
                            <i className="bi bi-x-lg" style={{ fontSize: '24px' }}></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleUpdatePassword}>
                            <Form.Group controlId="oldPassword" className="d-flex align-items-center mb-3">
                                <Form.Label className="me-2" style={{ minWidth: '130px' }}>Old Password</Form.Label>
                                <Form.Control type="password" placeholder="Enter old password" style={{ border: '1px solid #ced4da', borderRadius: '4px', padding: '5px' }} value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    required />
                            </Form.Group>
                            <Form.Group controlId="newPassword" className="d-flex align-items-center mb-3">
                                <Form.Label className="me-2" style={{ minWidth: '130px' }}>New Password</Form.Label>
                                <Form.Control type="password" placeholder="Enter new password" value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required style={{ border: '1px solid #ced4da', borderRadius: '4px', padding: '5px' }} />
                            </Form.Group>
                            <Form.Group controlId="confirmPassword" className="d-flex align-items-center mb-3">
                                <Form.Label className="me-2" style={{ minWidth: '130px' }}>Confirm Password</Form.Label>
                                <Form.Control type="password" placeholder="Confirm new password" value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required style={{ border: '1px solid #ced4da', borderRadius: '4px', padding: '5px' }} />
                            </Form.Group>
                            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                            <Button type="submit" variant="primary">Update Password</Button>

                        </Form>
                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="primary">Update Password</Button>
                    </Modal.Footer> */}
                </Modal>

            </div>
            <ToastContainer /> {/* Toast container for notifications */}
        </>
    );
};

// Component to render content based on item
const ContentRenderer = ({ item }) => {
    if (item === 'Student') {
        return <StudentList />;
    }
    // else if (item === 'Overview') {
    //     return < Overview />
    // }
    // else if (item === 'Teacher') {
    //     return < TeacherList />
    // }
    // else if (item === 'Calendar') {
    //     return < Calendar />
    // }
    // else if (item === 'Attendance') {
    //     return < Attendance />
    // }
    else if (item === 'Fees') {
        return < FeesManagement />
    }
    // else if (item === 'Results') {
    //     return < ResultOverview />
    // }
    else if (item === 'Enquiry') {
        return < Enquiry />
    }
    else {
        return (
            <div>
                <h2>{item} Details</h2>
                <p> {item} Under Development.</p>
            </div>
        );
    }
};

export default Dashboard;
