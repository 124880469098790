import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../assets/css/material-dashboard.css';
import '../assets/css/nucleo-icons.css';
import '../assets/css/nucleo-svg.css';
import StudentForm from './student_form'; // Add New Student Form
import ShowEdit from './showEdit'; // Edit Student Form
import ShowOverview from './showOverview'; // Overview Modal
import data from '../assets/data.json';
import { Chart, registerables, LinearScale } from 'chart.js';
import { ToastContainer, toast } from 'react-toastify'; // Import for toast notifications
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles

Chart.register(...registerables, LinearScale);

function AuthorsTable() {
  const [students, setStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [showForm, setShowForm] = useState(false); // Add New Student Form
  const [showEditForm, setShowEditForm] = useState(false); // Edit Student Form
  const [showOverview, setShowOverview] = useState(false); // Overview Popup
  const [selectedStudent, setSelectedStudent] = useState(null); // Store selected student for edit/overview
  const [API_PARENT_LINK, setApiParent] = useState(null);
  
  // const [API_PARENT_LINK, setApiParent2] = useState('');

  // Load the API URLs from data.json
  useEffect(() => {
    setApiParent(data.API_PARENT_LINK);
    // setApiParent2(data.API_PARENT_LINK);
  }, []);

  //console.log("students",students);





  // Fetch all students
  const [error, setError] = useState('');
  const fetchData = async () => {
    setError('');
    try {

      const sessionKey = encodeURIComponent(localStorage.getItem('session_key'));
      const sessionUser = localStorage.getItem('session_user');
      const school_id = localStorage.getItem('school_id');
      if (!sessionKey || sessionKey === 'null' || !sessionUser) {
        setError("Session key or user is missing. Please log in again.");
        return;
      }
      if (!API_PARENT_LINK) {
        throw new Error("API endpoint not available");
      }
      const apiUrl = `${API_PARENT_LINK}Students/?student_id=-1&session_key=${sessionKey}&session_user=${sessionUser}&school_id=${school_id}`;

      const response = await axios.get(apiUrl);
      setStudents(response.data.list_of_students);
      setFilteredStudents(response.data.list_of_students);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [API_PARENT_LINK]);
  // Function to refresh the student list after form submission
  const handleFormSubmitSuccess = () => {
    fetchData(); // Refresh student list after new student is added
  };
  const handleEditSubmitSuccess = () => {
    fetchData(); // Refresh student list after new student is added
  };


  // Search functionality
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = students.filter(student =>
      student.first_name.toLowerCase().includes(query) ||
      student.last_name.toLowerCase().includes(query) ||
      student.class.toLowerCase().includes(query) 
      // student.gender.toLowerCase().includes(query) ||
      // student.phone_number.includes(query) ||
      // student.address.toLowerCase().includes(query)
    );

    setFilteredStudents(filtered);
  };

  // Show the Overview Popup
  const handleOverviewClick = (student) => {
    setSelectedStudent(student);
    setShowOverview(true);
  };
  // Update or add this function in your component
  const handleDeleteClick = async (student) => {
    try {
      // Retrieve session data from localStorage
      const sessionKey = encodeURIComponent(localStorage.getItem('session_key'));
      const sessionUser = localStorage.getItem('session_user');
      const school_id = localStorage.getItem('school_id');
      if (!sessionKey || sessionKey === 'null' || !sessionUser) {
        setError("Session key or user is missing. Please log in again.");
        return;
      }
      if (!API_PARENT_LINK) {
        setError("API endpoint not available");
        return;
      }

      // Confirm deletion with the user
      if (!window.confirm(`Are you sure you want to delete ${student.full_name}?`)) {
        return;
      }

      // Construct the API URL
      const apiUrl = `${API_PARENT_LINK}Students/?student_id=${student.student_id}&session_key=${sessionKey}&session_user=${sessionUser}&school_id=${school_id}`;

      // Make the DELETE request
      await axios.delete(apiUrl);

      // Refresh the student list after deletion
      fetchData();

      // Show success notification
      toast.success('Student deleted successfully!');
    } catch (error) {
      console.error('Error deleting student: ', error);
      toast.error('Error deleting student');
    }
  };

  // Show the Edit Popup
  const handleEditClick = (student) => {
    setSelectedStudent(student);
    setShowEditForm(true);
  };

  // Show the Add New Student Popup
  const handleAddNewClick = () => {
    setSelectedStudent(null); // No student selected
    setShowForm(true); // Open the form for adding new student
  };

  return (
    <>
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-12">
            <div className="card my-4">
              <div className="card-header p-4 position-relative mt-n4 mx-3 z-index-2">
                <div className="shadow-primary border-radius-lg pt-4 pb-3 d-flex justify-content-between" style={{ backgroundColor: '#1A1469' }}>
                  <h6 className="text-white text-capitalize ps-3">Students Table</h6>
                  <button className="btn btn-light m-2" onClick={handleAddNewClick}>+ New Admission</button>
                </div>
              </div>
              <div className="card-body px-0 pb-2">
                <div className="px-3 m-2" style={{ border: '0.5px solid #1E176C', width: '60%', borderRadius: '50px' }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by name, class"
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                </div>
                <div className="table-responsive p-0" style={{ maxHeight: '600px', overflowY: 'auto', overflowX: 'auto' }}>
                  <table className="table  align-items-center mb-0">
                    <thead >
                      <tr >
                        <th className="text-xs font-weight-bold" style={{ color: '#1E176C', position: 'sticky', top: 0, backgroundColor: '#f8f9fa', zIndex: 2 }}>Full Name</th>
                        <th className="text-xs font-weight-bold" style={{ color: '#1E176C' , position: 'sticky', top: 0, backgroundColor: '#f8f9fa', zIndex: 2}}>Class</th>
                        <th className="text-xs font-weight-bold" style={{ color: '#1E176C' , position: 'sticky', top: 0, backgroundColor: '#f8f9fa', zIndex: 2}}>Gender</th>
                        <th className="text-xs font-weight-bold" style={{ color: '#1E176C' , position: 'sticky', top: 0, backgroundColor: '#f8f9fa', zIndex: 2}}>Phone Number</th>
                        <th className="text-xs font-weight-bold" style={{ color: '#1E176C', position: 'sticky', top: 0, backgroundColor: '#f8f9fa', zIndex: 2 }}>Address</th>
                        <th className="text-xs font-weight-bold" style={{ color: '#1E176C', position: 'sticky', top: 0, backgroundColor: '#f8f9fa', zIndex: 2 }}>Edit</th>
                        <th className="text-xs font-weight-bold" style={{ color: '#1E176C', position: 'sticky', top: 0, backgroundColor: '#f8f9fa', zIndex: 2 }}>Overview</th>
                        <th className="text-xs font-weight-bold " style={{ color: '#1E176C', position: 'sticky', top: 0, backgroundColor: '#f8f9fa', zIndex: 2}}>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredStudents.length > 0 ? (
                        
                          filteredStudents.map((student, index) => (
                            <tr key={index}>
                              <td>
                                <div className="d-flex px-4 py-1">
                                  <div className="d-flex flex-column justify-content-center">
                                    <h6 className="mb-0 text-sm font-weight-normal">{student.first_name}   {student.last_name
                                    }</h6>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p className="text-xs font-weight-normal mb-0 px-4">{student.class}-{student.section}</p>
                              </td>
                              <td>
                                <p className="text-xs font-weight-normal mb-0 px-4">{student.gender}</p>
                              </td>
                              <td>
                                <p className="text-xs font-weight-normal mb-0 px-4">{student.phone_number}</p>
                              </td>
                              <td>
                                <p className="text-xs font-weight-normal mb-0 px-4">{student.address}</p>
                              </td>
                              <td className="align-middle text-center text-sm">
                                <i
                                  className="bi bi-pencil-square"
                                  style={{ cursor: 'pointer', fontSize: '1.2rem' }}
                                  onClick={() => handleEditClick(student)}
                                ></i>
                              </td>
                              <td className="align-middle text-center text-sm">
                                <button className="btn btn-dark" onClick={() => handleOverviewClick(student)}>Overview</button>
                              </td>
                              <td className="align-middle text-center text-sm">
                                <button className="btn btn-danger" onClick={() => handleDeleteClick(student)}>Delete</button>
                              </td>
                            </tr>
                          ))) : (
                        <tr>
                          <td colSpan="8" className="text-center">
                            No Student Found
                          </td>
                        </tr>)}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add New Student Form */}
      {showForm && (
        <StudentForm
          show={showForm}
          onClose={() => setShowForm(false)}
          student={selectedStudent}
          onSubmitSuccess={handleFormSubmitSuccess}
        //onSubmit={handleFormSubmit} // Pass the submit handler for adding a new student
        />
      )}

      {/* Edit Student Form */}
      {showEditForm && selectedStudent && (
        <ShowEdit
          show={showEditForm}
          onClose={() => setShowEditForm(false)}
          onSubmitSuccess={handleEditSubmitSuccess} // Pass the submit handler for editing a student
          student={selectedStudent} // Pass the selected student for editing
        />
      )}

      {/* Overview Popup */}
      {showOverview && selectedStudent && (
        <ShowOverview
          show={showOverview}
          onClose={() => setShowOverview(false)}
          student={selectedStudent}
        />
      )}

      <ToastContainer /> {/* Toast container for notifications */}
    </>
  );
}

export default AuthorsTable;
