import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../assets/css/Dashboard.css';
import data from '../assets/data.json';
import { toast } from 'react-toastify';
const AddStudentModal = ({ show, onClose, onSubmitSuccess }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };
    const [currentAcademicYear, setCurrentAcademicYear] = useState('');

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        setCurrentAcademicYear(`${currentYear}-${currentYear + 1}`);
    }, []);
    useEffect(() => {
        setNewStudent((prevState) => ({
            ...prevState,
            academic_info: {
                ...prevState.academic_info,
                academic_year: currentAcademicYear,
            }
        }));
    }, [currentAcademicYear]);
    const [newStudent, setNewStudent] = useState({
        personal_info: {
            // regid: '',
            first_name: '',
            last_name: '',
            dob: '',
            gender: '',
            address: '',
            religion: 'Islam',
            aadhar: '',
            phone_number: '',
            whatsapp_number: '',
            blood_group: ''
        },
        academic_info: {
            school_id: '',
            roll_no: '',
            class: '',
            section: 'A',
            academic_year: `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`,
            enrollment_date: getCurrentDate(),
            concession_amount: '',
            admission_fee: ''
        },
        parent_guardian_info: {
            father_name: '',
            mother_name: '',
            emergency_contact: '',

        },
        amount_paid: '',
        admission_discount: ''
    });
    const [errors, setErrors] = useState({});
    const [finalAmount,setFinalAmount] = useState('0');
    const [showPreview, setShowPreview] = useState(false);
    const [error, setError] = useState('');
    const [admission, setAdmission] = useState('');
    const [API_PARENT_LINK, setApiParent] = useState('');
    useEffect(() => {
        setApiParent(data.API_PARENT_LINK);
    }, []);


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "personal_info.address" || name === "personal_info.first_name" || name === "personal_info.last_name") {
            const validCharacters = /^[a-zA-Z0-9\s.,-]*$/;
            if (!validCharacters.test(value)) return; // Prevent invalid characters
        }
        if (name === "amount_paid" || name === "admission_discount") {
            // Optional: Convert to number if required (e.g., to handle numeric input)
            const numericValue = value === '' ? '' : parseFloat(value); // If you want to allow empty strings

            // Update state with the numeric value
            setNewStudent(prevState => ({
                ...prevState,
                [name]: numericValue // Directly update the non-nested fields
            }));
        } else {
            const [section, field] = name.split('.');
            setNewStudent(prevState => ({
                ...prevState,
                [section]: { ...prevState[section], [field]: value }
            }));
        }
        setErrors({ ...errors, [name]: '' });
        // setNewStudent({
        //     ...newStudent,
        //     [e.target.name]: e.target.value

        // });
    };

    const validateField = (name, value) => {
        let error = '';

        switch (name) {
            case 'personal_info.first_name':
            case 'personal_info.last_name':
                // case 'parent_guardian_info.father_name':

                // case 'parent_guardian_info.mother_name':
                if (!value) error = 'This field cannot be empty';
                else if (value.length < 2) error = 'Minimum 2 characters required';
                break;

            case 'personal_info.gender':
                if (!value) error = 'Gender cannot be empty';
                else if (!['Male', 'Female'].includes(value)) error = 'Invalid gender';
                break;

            case 'academic_info.enrollment_date':
                if (!value) error = 'Required';
                // else if (!/^\d{2}\-\d{2}\-\d{4}$/.test(value)) error = 'Format must be DD/MM/YYYY';
                break;
            case 'personal_info.dob':
                if (!value) error = 'Required';
                // else if (!/^\d{2}\-\d{2}\-\d{4}$/.test(value)) error = 'Format must be DD/MM/YYYY';
                break;

            // case 'personal_info.address':
            //     //if (!value) error = 'Address cannot be empty';
            //      if (value.length < 5) error = 'Minimum 5 characters required';
            //     break;

            case 'personal_info.phone_number':
                //case 'parent_guardian_info.emergency_contact':
                if (!value) error = 'This field cannot be empty';
                else if (!/^\d{10}$/.test(value)) error = 'Must be exactly 10 digits';
                break;

            case 'personal_info.whatsapp_number':
                //if (!value) error = 'This field cannot be empty';
                if (value && !/^\d{10}$/.test(value)) error = 'Must be exactly 10 digits if provided';
                break;

            // case 'personal_info.religion':
            //     if (!value) error = 'Religion cannot be empty';
            //     // else if (!['Islam', 'Hinduism', 'Buddhist', 'Christian', 'Sikh', 'Jain', 'Other'].includes(value))
            //     //     error = 'Invalid religion';
            //     break;

            // case 'personal_info.blood_group':
            //     if (!value) error = 'Blood group cannot be empty';
            //     // else if (!['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'].includes(value))
            //     //     error = 'Invalid blood group';
            //     break;

            case 'personal_info.aadhar':
                if (value && !/^\d{12}$/.test(value)) error = 'Must be exactly 12 digits if provided';
                break;

            // case 'academic_info.roll_no':
            //     if (!value) error = 'Roll number cannot be empty';
            //     //else if (value.length < 2) error = 'Minimum 2 characters required';
            //     break;

            case 'academic_info.class':
                if (!value) error = 'Class cannot be empty';
                // else if (!['Nursery', 'LKG', 'UKG', '1', '2', '3', 'Class 4', 'Class 5', 'Class 6', 'Class 7', 'Class 8', 'Class 9', 'Class 10', 'Class 11', 'Class 12'].includes(value))
                //     error = 'Invalid class';
                break;

            case 'academic_info.section':
                if (!value) error = 'Section cannot be empty';
                // else if (!['A', 'B', 'C', 'D'].includes(value)) error = 'Invalid section';
                break;
            case 'academic_info.school_id':
                if (!value) error = 'School ID cannot be empty';

                break;

            case 'academic_info.academic_year':
                if (!value) error = 'Academic year cannot be empty';
                // else if (!['2023-2024', '2024-2025', '2025-2026'].includes(value)) error = 'Invalid academic year';
                break;

            // case 'academic_info.admission_fee':
            //     if (!value) error = 'Admission fee cannot be empty';
            //     else if (isNaN(value) || parseFloat(value) <= 0) error = 'Invalid amount';
            //     break;

            // case 'academic_info.concession_amount':
            //     if (value && (isNaN(value) || parseFloat(value) < 0)) error = 'Invalid amount';
            //     break;

            default:
                break;
        }

        return error;
    };
    // Form-level validation
    const validateForm = () => {
        const newErrors = {};

        Object.keys(newStudent).forEach(section => {
            Object.keys(newStudent[section]).forEach(field => {
                const fieldName = `${section}.${field}`;
                const error = validateField(fieldName, newStudent[section][field]);
                if (error) newErrors[fieldName] = error;
            });
        });


        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    // Helper function to convert all values in an object to strings
    const convertValuesToString = (obj) => {
        return Object.fromEntries(
            Object.entries(obj).map(([key, value]) => {
                if (typeof value === 'object' && value !== null) {
                    return [key, convertValuesToString(value)]; // Recursive call for nested objects
                } else if ((key === 'dob' && typeof value === 'string') || (key === 'enrollment_date' && typeof value === 'string')) {
                    // Check if the date is in dd/mm/yyyy format
                    const [day, month, year] = value.split('/');
                    if (day && month && year) {
                        return [key, `${year}-${month}-${day}`]; // Convert to yyyy-mm-dd format
                    }
                }
                else if (key === 'admission_fee' || key === 'concession_amount' || key === 'admission_discount' || key === 'amount_paid') {
                    // Keep admission_fee and concession_amount as numbers
                    return [key, value ? parseFloat(value) : 0];
                }
                return [key, String(value)]; // Convert value to string
            })
        );
    };


    const handleFormSubmit = async (event) => {
        event.preventDefault();
        //validateForm()

        try {
            const sessionKey = encodeURIComponent(localStorage.getItem('session_key'));
            const sessionUser = localStorage.getItem('session_user');
            const school_id = localStorage.getItem('school_id');
            const studentDataAsStrings = convertValuesToString(newStudent);
            //console.log(studentDataAsStrings);

            const apiUrl = `${API_PARENT_LINK}Students/?session_key=${sessionKey}&session_user=${sessionUser}&device_id=Web&school_id=${school_id}`;
            //console.log("studentDataAsStrings", studentDataAsStrings);

            const response = await axios.post(apiUrl, studentDataAsStrings);

            if (response.status === 200 && response.data.success) {
                toast.success('Student added successfully!');
                onSubmitSuccess();
                onClose();
            }
        } catch (error) {
            setErrors({ api: error.response?.data?.message || error.message });
        }
    };
    const fetchData = async (Student) => {
        try {
            //console.log(Student);

            const sessionKey = encodeURIComponent(localStorage.getItem('session_key'));
            const sessionUser = localStorage.getItem('session_user');
            const school_id = localStorage.getItem('school_id');
            const classSelected = newStudent.academic_info.class;
            const Amount = newStudent.academic_info?.concession_amount
                ? newStudent.academic_info.concession_amount.toString()
                : '0';
            //console.log("Amount", Amount);
            //https://danisheazam.pythonanywhere.com/FeeManagement/get-admission-fees/?session_key=ALCc3ndjNmSfRmhU2LUn0W&session_user=TAhsan&class=2&concession_amount=0

            const apiUrl = `${API_PARENT_LINK}FeeManagement/get-admission-fees/?session_key=${sessionKey}&session_user=${sessionUser}&class=${classSelected}&concession_amount=${Amount}&school_id=${school_id}`;

            const response = await axios.get(apiUrl);
            //console.log("response", response);
            setAdmission(response.data)
            setFinalAmount(response.data.total_due_amount)




            if (response.status === 200 && response.data.success) {
                //toast.success('Student added successfully!');
                onSubmitSuccess();
                onClose();
            }
        } catch (error) {
            setErrors({ api: error.response?.data?.message || error.message });
        }
    };

    // Toggle preview modal
    const handleNext = async () => {
        // Add any validation logic here if needed
        if (!validateForm()) {
            return;

        }
        await fetchData(newStudent);
        //console.log(admission.details.concession_amount);

        setShowPreview(true);
    };

    const handleBack = () => {
        setShowPreview(false);
    };
    // Calculate final fee breakdown
    // const admissionFee = parseFloat(newStudent.academic_info.admission_fee) || 0;
    // const concessionAmount = parseFloat(newStudent.academic_info.concession_amount) || 0;
     //const finalAmount = admission.total_due_amount - newStudent.admission_discount;
     const handlefee = (e) => {
        const { name, value } = e.target;
        setNewStudent((prevState) => ({
          ...prevState,
          [name]: value, // Update the field dynamically
        }));
      
        // Update the final amount based on the admission discount input
        setFinalAmount(admission.total_due_amount - (value || 0));
      };


    return (
        <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" aria-hidden={!show}>
            {!showPreview && (
                <div className="modal-dialog modal-lg modal-dialog-centered modal-l">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add New Student</h5>
                            <button
                                type="button"
                                onClick={onClose}
                                className="btn btn-link text-dark"
                                style={{ padding: 0 }}
                                aria-label="Close"
                            >
                                <i className="bi bi-x-lg" style={{ fontSize: '24px' }}></i>
                            </button>                    </div>
                        <div className="modal-body p-4 ">
                            <form onSubmit={handleFormSubmit}>

                                {/* Personal Information Section */}
                                <h5 className='mb-4'>Personal Information</h5>
                                <div className="row">

                                    <div className="col-md-6 mb-3 floating-label">

                                        <input
                                            type="text"
                                            name="personal_info.first_name"
                                            value={newStudent.personal_info.first_name}
                                            onChange={handleInputChange}
                                            className={`form-control ${newStudent.personal_info.first_name ? 'has-value' : ''} pb-2`}
                                            style={{ border: '1px solid gray' }}
                                            pattern="[a-zA-Z0-9\s.,-]*"
                                        />
                                        <label className="form-label pb-0" >First Name*</label>
                                        {errors['personal_info.first_name'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['personal_info.first_name']}</p>}
                                    </div>

                                    <div className="col-md-6 mb-3 floating-label">

                                        <input
                                            type="text"
                                            name="personal_info.last_name"
                                            pattern="[a-zA-Z0-9\s.,-]*"
                                            value={newStudent.personal_info.last_name}
                                            onChange={handleInputChange}
                                            className={`form-control ${newStudent.personal_info.last_name ? 'has-value' : ''} pb-2 `}
                                            style={{ border: '1px solid gray' }}
                                        />
                                        <label className="form-label pb-0">Last Name*</label>
                                        {errors['personal_info.last_name'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['personal_info.last_name']}</p>}
                                    </div>
                                    <div className="col-md-6 mb-3 floating-label">

                                        <input
                                            type="text"
                                            name="personal_info.address"
                                            value={newStudent.personal_info.address}
                                            onChange={handleInputChange}
                                            className={`form-control ${newStudent.personal_info.address ? 'has-value' : ''} pb-2 `}
                                            style={{ border: '1px solid gray' }}
                                            pattern="[a-zA-Z0-9\s.,-]*"
                                        />
                                        <label className="form-label pb-0">Address</label>
                                        {errors['personal_info.address'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['personal_info.address']}</p>}
                                    </div>
                                    <div className="col-md-6 mb-3 floating-label">

                                        <input
                                            type="text"
                                            name="personal_info.aadhar"
                                            value={newStudent.personal_info.aadhar}
                                            onChange={handleInputChange}
                                            className={`form-control ${newStudent.personal_info.aadhar ? 'has-value' : ''} pb-2 `}
                                            style={{ border: '1px solid gray' }}
                                        />
                                        <label className="form-label pb-0">Aadhar Card (Optional)</label>
                                        {errors['personal_info.aadhar'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['personal_info.aadhar']}</p>}
                                    </div>

                                    <div className="col-md-6 mb-3 floating-label">

                                        <input
                                            type="text"
                                            name="personal_info.phone_number"
                                            value={newStudent.personal_info.phone_number}
                                            onChange={handleInputChange}
                                            className={`form-control ${newStudent.personal_info.phone_number ? 'has-value' : ''} pb-2 `}
                                            maxLength={10}
                                            style={{ border: '1px solid gray' }}
                                        />
                                        <label className="form-label pb-0 ">Phone Number*</label>
                                        {errors['personal_info.phone_number'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['personal_info.phone_number']}</p>}
                                    </div>
                                    <div className="col-md-6 mb-2 floating-label">

                                        <input
                                            type="text"
                                            name="personal_info.whatsapp_number"
                                            value={newStudent.personal_info.whatsapp_number}
                                            onChange={handleInputChange}
                                            maxLength={10}
                                            className={`form-control ${newStudent.personal_info.whatsapp_number ? 'has-value' : ''} pb-2 `}
                                            style={{ border: '1px solid gray' }}
                                        />
                                        <label className="form-label pb-2">WhatsApp Number </label>
                                        {errors['personal_info.whatsapp_number'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['personal_info.whatsapp_number']}</p>}
                                    </div>



                                    <div className="col-md-6 mb-2 ">
                                        <label className={`form-label p-0 m-0`}>Date of Birth* </label>


                                        <input
                                            type="date"
                                            name="personal_info.dob"
                                            value={newStudent.personal_info.dob}
                                            placeholder=""
                                            onChange={handleInputChange}
                                            className={`form-control ${newStudent.personal_info.dob ? 'has-value' : ''} p-2 `}
                                            style={{ border: '1px solid gray' }}
                                        />
                                        {errors['personal_info.dob'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['personal_info.dob']}</p>}
                                    </div>

                                    <div className="col-md-6 mb-2 ">
                                        <label className="form-label p-0">Gender*</label>

                                        <select
                                            name="personal_info.gender"
                                            value={newStudent.personal_info.gender}
                                            onChange={handleInputChange}
                                            className={` form-select ${newStudent.personal_info.gender ? 'has-value' : ''} p-2 `}
                                            style={{ border: '1px solid gray' }}
                                        >
                                            <option value="" disabled hidden></option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            
                                        </select>

                                        {errors['personal_info.gender'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['personal_info.gender']}</p>}
                                    </div>



                                    <div className="col-md-6 mb-2 ">
                                        <label className="form-label">Religion</label>

                                        <select
                                            name="personal_info.religion"
                                            value={newStudent.personal_info.religion}
                                            onChange={handleInputChange}
                                            className={`form-select ${newStudent.personal_info.religion ? 'has-value' : ''} p-2 `}
                                            style={{ border: '1px solid gray' }}
                                        >
                                            {/* <option value="" disabled hidden></option> */}
                                            <option value="Islam">Islam</option>
                                            <option value="Hinduism">Hinduism</option>
                                            <option value="Buddhist">Buddhist</option>
                                            <option value="Christian">Christian</option>
                                            <option value="Sikh">Sikh</option>
                                            <option value="Jain">Jain</option>
                                            <option value="Other">Other</option>
                                        </select>

                                        {errors['personal_info.religion'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['personal_info.religion']}</p>}
                                    </div>







                                    <div className="col-md-6 mb-2 ">
                                        <label className="form-label">Blood Group</label>
                                        <select
                                            name="personal_info.blood_group"
                                            value={newStudent.personal_info.blood_group}
                                            onChange={handleInputChange}
                                            className={`form-select ${newStudent.personal_info.blood_group ? 'has-value' : ''} p-2`}
                                            style={{ border: '1px solid gray' }}
                                        >
                                            <option value="" disabled hidden></option>
                                            <option value="A+">A+</option>
                                            <option value="A-">A-</option>
                                            <option value="B+">B+</option>
                                            <option value="B-">B-</option>
                                            <option value="AB+">AB+</option>
                                            <option value="AB-">AB-</option>
                                            <option value="O+">O+</option>
                                            <option value="O-">O-</option>
                                        </select>

                                        {errors['personal_info.blood_group'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['personal_info.blood_group']}</p>}
                                    </div>

                                </div>
                                <hr></hr>

                                {/* Academic Information Section */}
                                <h5 className='mb-4'>Academic Information</h5>
                                <div className="row">
                                    <div className="col-md-6 mb-2 floating-label">

                                        <input
                                            type="text"
                                            name="academic_info.roll_no"
                                            value={newStudent.academic_info.roll_no}
                                            onChange={handleInputChange}
                                            className={`form-control ${newStudent.academic_info.roll_no ? 'has-value' : ''} pb-2 `}
                                            style={{ border: '1px solid gray' }}
                                        />
                                        <label className="form-label pb-2">Roll Number</label>
                                        {errors['academic_info.roll_no'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['academic_info.roll_no']}</p>}
                                    </div>
                                    <div className="col-md-6 mb-2 floating-label">

                                        <input
                                            type="text"
                                            name="academic_info.concession_amount"
                                            value={newStudent.academic_info.concession_amount}
                                            onChange={handleInputChange}
                                            className={`form-control ${newStudent.academic_info.concession_amount ? 'has-value' : ''} pb-2 `}
                                            style={{ border: '1px solid gray' }}
                                        />
                                        <label className="form-label pb-2">Concession Amount</label>
                                        {errors['academic_info.concession_amount'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['academic_info.concession_amount']}</p>}
                                    </div>

                                    <div className="col-md-6 mb-2">
                                        <label className="form-label p-0">Class*</label>

                                        <select
                                            name="academic_info.class"
                                            value={newStudent.academic_info.class}
                                            onChange={handleInputChange}
                                            className={`form-select ${newStudent.academic_info.class ? 'has-value' : ''} p-2`}
                                            style={{ border: '1px solid gray' }}
                                        >
                                            <option value="" disabled hidden></option>
                                            <option value="Nursery">Nursery</option>
                                            <option value="LKG">LKG</option>
                                            <option value="UKG">UKG</option>
                                            {/* Add all classes as options */}
                                            {[...Array(10)].map((_, i) => (
                                                <option key={i + 1} value={i + 1}>
                                                    {i + 1}
                                                </option>
                                            ))}
                                            {/* <option value="Class 12">Class 12</option> */}
                                        </select>

                                        {errors['academic_info.class'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['academic_info.class']}</p>}
                                    </div>

                                    <div className="col-md-6 mb-2 ">
                                        <label className="form-label p-0">Section*</label>

                                        <select
                                            name="academic_info.section"
                                            value={newStudent.academic_info.section}
                                            onChange={handleInputChange}
                                            className={`form-select ${newStudent.academic_info.section ? 'has-value' : ''} p-2`}
                                            style={{ border: '1px solid gray' }}
                                        >
                                            {/* <option value="" disabled hidden></option> */}
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                            <option value="D">D</option>
                                        </select>

                                        {errors['academic_info.section'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['academic_info.section']}</p>}
                                    </div>

                                    <div className="col-md-6 mb-2">
                                        <label className="form-label p-0">Academic Year*</label>

                                        <input
                                            type="text"
                                            name="academic_info.academic_year"
                                            value={currentAcademicYear}
                                            onChange={handleInputChange}
                                            readOnly
                                            className={`form-control ${newStudent.academic_info.academic_year ? 'has-value' : ''} p-2`}
                                            style={{ border: '1px solid gray' }}
                                        />

                                        {errors['academic_info.academic_year'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['academic_info.academic_year']}</p>}
                                    </div>

                                    <div className="col-md-6 mb-3 ">
                                        <label className="form-label p-0">Enrollment Date</label>
                                        <input
                                            type="date"
                                            name="academic_info.enrollment_date"
                                            value={newStudent.academic_info.enrollment_date}
                                            onChange={handleInputChange}
                                            className={`form-control ${newStudent.academic_info.enrollment_date ? 'has-value' : ''} p-2`}
                                            style={{ border: '1px solid gray' }}
                                        />

                                        {errors['academic_info.enrollment_date'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['academic_info.enrollment_date']}</p>}
                                    </div>




                                    <div className="col-md-6 mb-3 ">


                                        <label className="form-label p-0" >School ID*</label>
                                        <select
                                            name="academic_info.school_id"
                                            value={newStudent.academic_info.school_id}
                                            onChange={handleInputChange}
                                            className={`form-select ${newStudent.academic_info.school_id ? 'has-value' : ''} p-2 `}
                                            style={{ border: '1px solid gray' }}
                                        >
                                            <option value="" disabled hidden></option>
                                            <option value="1">Morning Bells - Senior Wing</option>
                                            <option value="2">Morning Bells - Junior Wing</option>

                                        </select>
                                        {errors['academic_info.school_id'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['academic_info.school_id']}</p>}
                                    </div>
                                    <div className="col-md-6 mb-3 floating-label">

                                        <input
                                            type="text"
                                            name="academic_info.admission_fee"
                                            value={newStudent.academic_info.admission_fee}
                                            onChange={handleInputChange}
                                            className={`form-control ${newStudent.academic_info.admission_fee ? 'has-value' : ''} pb-2`}
                                            style={{ border: '1px solid gray' }}
                                        />
                                        <label className="form-label pb-0">Admission Fee</label>
                                        {errors['academic_info.admission_fee'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['academic_info.admission_fee']}</p>}
                                    </div>
                                </div>
                                <hr></hr>
                                {/* Parent/Guardian Information Section */}
                                <h5 className='mb-4'>Parent/Guardian Information</h5>
                                <div className="row">
                                    <div className="col-md-6 mb-3 floating-label">

                                        <input
                                            type="text"
                                            name="parent_guardian_info.father_name"
                                            value={newStudent.parent_guardian_info.father_name}
                                            onChange={handleInputChange}
                                            className={`form-control ${newStudent.parent_guardian_info.father_name ? 'has-value' : ''} pb-2`}
                                            style={{ border: '1px solid gray' }}
                                        />
                                        <label className="form-label pb-2">Father's Name</label>
                                        {errors['parent_guardian_info.father_name'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['parent_guardian_info.father_name']}</p>}
                                    </div>

                                    <div className="col-md-6 mb-3 floating-label">

                                        <input
                                            type="text"
                                            name="parent_guardian_info.mother_name"
                                            value={newStudent.parent_guardian_info.mother_name}
                                            onChange={handleInputChange}
                                            className={`form-control ${newStudent.parent_guardian_info.mother_name ? 'has-value' : ''} pb-2 `}
                                            style={{ border: '1px solid gray' }}
                                        />
                                        <label className="form-label pb-2">Mother's Name</label>
                                        {errors['parent_guardian_info.mother_name'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['parent_guardian_info.mother_name']}</p>}
                                    </div>

                                    <div className="col-md-6 mb-3 floating-label">

                                        <input
                                            type="text"
                                            name="parent_guardian_info.emergency_contact"
                                            value={newStudent.parent_guardian_info.emergency_contact}
                                            onChange={handleInputChange}
                                            maxLength={10}
                                            className={`form-control ${newStudent.parent_guardian_info.emergency_contact ? 'has-value' : ''} pb-2`}
                                            style={{ border: '1px solid gray' }}
                                        />
                                        <label className="form-label pb-2">Emergency Contact</label>
                                        {errors['parent_guardian_info.emergency_contact'] && <p className="text-danger " style={{ fontSize: '0.775rem' }}>{errors['parent_guardian_info.emergency_contact']}</p>}

                                    </div>
                                </div>

                                {/* Submit Button */}
                                <div className="d-flex justify-content-end">
                                    <button type="button" className="btn btn-secondary me-2" onClick={onClose}>Close</button>
                                    {/* <button type="submit" className="btn btn-primary">Add Student</button> */}
                                    <button type="button" className="btn btn-primary" onClick={handleNext}>Next</button>

                                </div>
                            </form>
                            {/* {errors.api && <p className="text-danger mt-2">{errors.api}</p>} */}
                        </div>
                    </div>
                </div>)}
            {/* Preview Modal */}
            {showPreview && (
                <div className="modal show d-block " tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Student Information</h5>
                                {/* <button
                                    type="button"
                                    onClick={onClose}
                                    className="btn btn-link text-dark"
                                    style={{ padding: 0 }}
                                    aria-label="Close"
                                >
                                    <i className="bi bi-x-lg" style={{ fontSize: '24px' }}></i>
                                </button>   */}
                            </div>
                            <div className="modal-body p-4">
                                <div className="row">
                                    {/* Personal Information Column */}
                                    <div className="col-md-6">
                                        <h4 style={{ color: '#1A1469' }}>Personal Information</h4>
                                        <p><strong>Address:</strong> {newStudent.personal_info.address}</p>
                                        <p><strong>Aadhar Card:</strong> {newStudent.personal_info.aadhar || 'N/A'}</p>
                                        <p><strong>Phone Number:</strong> {newStudent.personal_info.phone_number}</p>
                                        <p><strong>WhatsApp Number:</strong> {newStudent.personal_info.whatsapp_number}</p>
                                        <p><strong>Date of Birth:</strong> {newStudent.personal_info.dob}</p>
                                        <p><strong>Gender:</strong> {newStudent.personal_info.gender}</p>
                                        <p><strong>Religion:</strong> {newStudent.personal_info.religion}</p>
                                        <p><strong>Blood Group:</strong> {newStudent.personal_info.blood_group}</p>
                                    </div>

                                    {/* Academic and Parent Information Column */}
                                    <div className="col-md-6">
                                        <h4 style={{ color: '#1A1469' }}>Academic Information</h4>
                                        <p><strong>Roll Number:</strong> {newStudent.academic_info.roll_no}</p>
                                        <p><strong>Class:</strong> {newStudent.academic_info.class}</p>
                                        <p><strong>Section:</strong> {newStudent.academic_info.section}</p>
                                        <p><strong>Academic Year:</strong> {newStudent.academic_info.academic_year}</p>
                                        <p><strong>Enrollment Date:</strong> {newStudent.academic_info.enrollment_date}</p>
                                        <p><strong>School ID:</strong> {newStudent.academic_info.school_id === '1'
                                            ? "Morning Bells - Senior Wing"
                                            : "Morning Bells - Junior Wing"}</p>



                                        <h4 style={{ color: '#1A1469' }}>Parent/Guardian Information</h4>
                                        <p><strong>Father's Name:</strong> {newStudent.parent_guardian_info.father_name}</p>
                                        <p><strong>Mother's Name:</strong> {newStudent.parent_guardian_info.mother_name}</p>
                                        <p><strong>Emergency Contact:</strong> {newStudent.parent_guardian_info.emergency_contact}</p>
                                    </div>
                                </div>

                                <hr className="my-3 bg-dark" />

                                {/* Admission Fee Breakdown */}
                                <h4 style={{ color: '#1A1469' }}>Admission Fee Breakdown</h4>
                                <p><strong>Concession Amount:</strong> ₹ {admission.details[100]?.amount}</p>
                                <p><strong>Tution Fee:</strong> ₹{admission.details[1]?.amount}</p>
                                <p><strong>Admission Fee:</strong> ₹{admission.details[4]?.amount}</p>
                                <p><strong>Fee Book:</strong> ₹{admission.details[5]?.amount}</p>
                                <p><strong>ID Card:</strong> ₹{admission.details[6]?.amount}</p>
                                <p><strong>Form:</strong> ₹{admission.details[7]?.amount}</p>
                                <p><strong>Caution Money:</strong> ₹{admission.details[9]?.amount}</p>
                                <div class="row justify-content-between">
                                    <div className="col-md-5 mb-2 floating-label">

                                        <input
                                            type="text"
                                            name="admission_discount"
                                            value={newStudent.admission_discount|| ''}
                                            onChange={handlefee}
                                            className={`form-control ${newStudent.admission_discount ? 'has-value' : ''} pb-2 `}
                                            style={{ border: '1px solid gray' }}
                                        />
                                        <label className="form-label pb-2">Admission Discount</label>
                                        {errors['admission_discount'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['admission_discount']}</p>}
                                    </div>
                                    <div className="col-md-6 mb-2 floating-label">

                                        <input
                                            type="text"
                                            name="amount_paid"
                                            value={newStudent.amount_paid}
                                            onChange={handleInputChange}
                                            className={`form-control ${newStudent.amount_paid ? 'has-value' : ''} pb-2  `}
                                            style={{ border: '1px solid gray' }}
                                        />
                                        <label className="form-label pb-2"> Amount Paid</label>
                                        {errors['amount_paid'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['amount_paid']}</p>}
                                    </div>
                                </div>

                                <hr className="my-3 bg-dark" />
                                <p><strong>Total Due Amount:</strong> ₹{finalAmount}</p>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleBack}>Back</button>
                                <button type="button" className="btn btn-primary" onClick={handleFormSubmit}>Submit</button>
                                <button type="button" className="btn btn-danger" onClick={onClose}>Cancel</button>
                            </div>
                            {errors.api && <p className="text-danger mt-2 ml-2 p-2">{errors.api}</p>}
                        </div>
                    </div>

                </div>
            )}

        </div>

    );
};

export default AddStudentModal;
