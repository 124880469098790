import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "./assets/css/login.css";
import data from './assets/data.json';
import gallerypic2 from './assets/img/gallery/gallerypic2.jpg'
import gallerypic3 from './assets/img/gallery/gallerypic3.jpg'
import gallerypic4 from './assets/img/gallery/gallerypic4.jpg'
import FrontSchool from './assets/img/gallery/schoolfronthd.jpg'
import { ToastContainer, toast } from 'react-toastify';
import LOGO from "./images/logo.jpg"


import './images/logo.jpg';

function Login() {
    const [API_PARENT_LINK, setApiParent] = useState('');
    const [regid, setRegid] = useState('');
    const [password, setPassword] = useState('');
    const [school_id, setSchoolID] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // State for loader
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const navigate = useNavigate();
    const images = [
        
        // gallerypic2,
        FrontSchool,
        // gallerypic4,
    ];

    // Change image every 3 seconds
    useEffect(() => {
        localStorage.clear();
        setApiParent(data.API_PARENT_LINK);
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Clear previous errors

        // Ensure API_PARENT_LINK is set before proceeding
        if (!API_PARENT_LINK) {
            toast.error('Login service not available. Please try again.');
            return;
        }

        setLoading(true); // Show loader while waiting for response

        try {
            console.log(localStorage);
            
            const apiUrl = `${API_PARENT_LINK}Login/`
            const response = await axios.post(apiUrl, {
                regid,
                password,
                school_id,
                deviceid: 'Web'

            });
            console.log(response.data);
            console.log(response.data.role);


            if (response.data.success) {
                // Store session key in localStorage
                
                // Redirect to Hello World page
                if (response.data.role === 'admin') {
                    localStorage.setItem('session_key', response.data.session_key);
                    localStorage.setItem('session_user', regid);
                    localStorage.setItem('school_id', school_id);
                    navigate('/dashboard');
                } else {
                    toast.error('Dashboard is in progress.');
                }
            } else {
                // Handle other responses
                toast.error('Login failed. Please try again.');
            }
        } catch (error) {
            // Handle error
            //console.error('Error logging in:', error.response ? error.response.data : error.message);
            toast.error(error.response?.data?.message || 'Incorrect REG-ID or Password. Please try again.');
        } finally {
            setLoading(false); // Hide loader after request completes
        }
    };


    return (
        <>
        <div style={{ minHeight: '100vh', display: 'flex', alignItems: 'center', backgroundImage: `url(${images[currentImageIndex]})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#f1f1f1' }}>
            <Row className="w-100 g-0">
                {/* Left Column - Image Section */}
                {/* <Col md={6} className="d-flex align-items-center justify-content-center  " style={{ position: 'relative', overflow: 'hidden',border:'px solid black', minHeight: '100vh'}}>

                    <div className='d-flex align-items-center m-3' style={{ position: 'absolute', top: '0px', left: '20px', color: '#fff', textAlign: 'center' }}>
                        <img src={LOGO} alt="School Logo" style={{ width: '50px', height: '50px', marginRight: '10px', borderRadius: '50%' }} />

                        <h1 style={{ fontWeight: 'bold', fontSize: '1.5rem' ,color:'white', fontFamily:'cursive',margin:'0px'}}> The Morning Bells International School </h1>
                    </div>
                    <img
                        src={images[currentImageIndex]}
                        alt="Slideshow"
                        // className='rounded-4'
                        style={{ width: '100%', objectFit: 'cover', opacity: 0.6, minHeight: '100vh'}}
                    />
                    
                </Col> */}

                {/* Right Column - Form Section */}
                <Col md={12} className="d-flex align-items-center justify-content-center " style={{ padding: '3rem', background: 'transparent' }}>
                    <div className ='shadow-sm p-4 rounded' style={{ width: '100%', maxWidth: '400px' ,backgroundColor:'white',opacity:'2'}}>
                        <h2 style={{ fontSize: '2rem', color: '#333', fontWeight: 'bold' }}>Login</h2>
                        {/* <p style={{ color: '#a9a9b3' }}>Already have an account? <a href="/login" style={{ color: '#7158e2' }}>Log in</a></p> */}

                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Registration ID"
                                            value={regid}
                                            onChange={(e) => setRegid(e.target.value)}
                                            style={{
                                                backgroundColor: '#f0f2f5',
                                                color: '#333',
                                                border: '1px solid #ddd',
                                                padding: '0.75rem',
                                                borderRadius: '0.5rem'
                                            }} required
                                        />
                                    </Form.Group>
                                </Col>
                                {/* <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Last Name"
                                            style={{
                                                backgroundColor: '#f0f2f5',
                                                color: '#333',
                                                border: '1px solid #ddd',
                                                padding: '0.75rem',
                                                borderRadius: '0.5rem'
                                            }}
                                        />
                                    </Form.Group>
                                </Col> */}
                            </Row>

                            {/* <Form.Group className="mb-3">
                                <Form.Control
                                    type="email"
                                    placeholder="Email"
                                    style={{
                                        backgroundColor: '#f0f2f5',
                                        color: '#333',
                                        border: '1px solid #ddd',
                                        padding: '0.75rem',
                                        borderRadius: '0.5rem'
                                    }}
                                />
                            </Form.Group> */}

                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="password"
                                    placeholder="Enter your password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    style={{
                                        backgroundColor: '#f0f2f5',
                                        color: '#333',
                                        border: '1px solid #ddd',
                                        padding: '0.75rem',
                                        borderRadius: '0.5rem'
                                    }} required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                            <select
                                        className="form-select mt-3 p-2"
                                        name="school"
                                        id="school"
                                        aria-label="Select School"
                                        style={{
                                            //boxShadow: 'inset 0 2px 5px rgba(0, 0, 0, 0.3)',
                                            backgroundColor: '#f0f2f5',
                                        color: '#333',
                                        border: '1px solid #ddd',
                                        padding: '0.75rem',
                                        borderRadius: '0.5rem'
                                        }}
                                        onChange={(e) => {
                                            const selectedSchool = e.target.value;
                                            if (selectedSchool === "Morning Bells - BS") {
                                                setSchoolID(1);
                                            } else if (selectedSchool === "Morning Bells - PR") {
                                                setSchoolID(2);
                                            }
                                        }}
                                        required
                                    >
                                        <option value="">Select School</option>
                                        <option value="Morning Bells - BS">Morning Bells - Senior Wing</option>
                                        <option value="Morning Bells - PR">Morning Bells - Junior Wing</option>
                                    </select>
                            </Form.Group>

                            {/* <Form.Group className="mb-3 d-flex align-items-center">
                                <Form.Check type="checkbox" style={{ marginRight: '10px' }} />
                                <span style={{ color: '#555' }}>
                                    I agree to the <a href="#" style={{ color: '#7158e2' }}>Terms & Conditions</a>
                                </span>
                            </Form.Group> */}

                            <Button variant="primary" type="submit" style={{
                                width: '100%',
                                backgroundColor: '#7158e2',
                                border: 'none',
                                padding: '0.75rem',
                                borderRadius: '0.5rem',
                                marginBottom: '1rem'
                            }} onClick={handleSubmit}>
                                Login
                            </Button>

                            {/* <p className="text-center" style={{ color: '#555' }}>or register with</p>
                            <div className="d-flex justify-content-center">
                                <Button variant="outline-secondary" className="me-2" style={{ color: '#555', borderColor: '#ddd' }}>
                                    <i className="fab fa-google"></i> Google
                                </Button>
                                <Button variant="outline-secondary" style={{ color: '#555', borderColor: '#ddd' }}>
                                    <i className="fab fa-apple"></i> Apple
                                </Button>
                            </div> */}
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
        <ToastContainer /> {/* Toast container for notifications */}
        </>
    );
}

export default Login;
