// AttendanceChart.js
import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import Select from 'react-select';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    Title,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, Title);

// Sample Data with Total Days and Present Days
const sampleData = {
    "This Month": [20, 18, 19, 16, 17, 22, 21, 19, 20, 18], // Present days out of 30
    "Last Month": [15, 17, 14, 12, 18, 20, 19, 13, 15, 16],
    "This Week": [5, 6, 4, 5, 7, 6, 6, 5, 7, 6], // Present days out of 7
    "Last Week": [3, 5, 4, 2, 6, 7, 5, 4, 5, 3],
    "This Year": [180, 190, 175, 160, 185, 200, 192, 178, 195, 210], // Present days out of 220
    "Last Year": [170, 180, 168, 155, 188, 185, 180, 160, 185, 190],
};

const totalDaysData = {
    "This Month": 30,
    "Last Month": 30,
    "This Week": 7,
    "Last Week": 7,
    "This Year": 220,
    "Last Year": 220,
};

// Dropdown options
const periodOptions = [
    { value: "This Month", label: "This Month" },
    { value: "Last Month", label: "Last Month" },
    { value: "This Week", label: "This Week" },
    { value: "Last Week", label: "Last Week" },
    { value: "This Year", label: "This Year" },
    { value: "Last Year", label: "Last Year" },
];

const Attendance = () => {
    const [selectedPeriod, setSelectedPeriod] = useState("This Month");
    const [chartData, setChartData] = useState([]);

    // Update chart data based on the selected period
    useEffect(() => {
        setChartData(sampleData[selectedPeriod]);
    }, [selectedPeriod]);

    const totalDays = totalDaysData[selectedPeriod];

    const data = {
        labels: [
            "Class 1", "Class 2", "Class 3", "Class 4", "Class 5",
            "Class 6", "Class 7", "Class 8", "Class 9", "Class 10",
        ],
        datasets: [
            {
                label: false,
                data: chartData.map((presentDays) => (presentDays / totalDays) * 100),
                backgroundColor: [
                    "#FF5733", // Red-orange
                    "#33FF57", // Green
                    "#3357FF", // Blue
                    "#FF33A1", // Pink
                    "#FFC300", // Yellow
                    "#DAF7A6", // Light green
                    "#900C3F", // Maroon
                    "#581845", // Purple
                    "#C70039", // Crimson
                    "#1ABC9C", // Teal
                ],
                //backgroundColor: '#4a90e2', // Colored bars (light blue)
                borderColor: "white",
                borderWidth: 1,
                borderRadius: 5, // Rounded bars
                hoverBackgroundColor: '#5DADE2',
                barThickness: 25,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        padding:'0px',
        plugins: {
            legend: {
                display: false, // Disable the legend to avoid label issues
            },
            title: {
                display: true,
                text: `Attendance - ${selectedPeriod}`,
                font: {
                    size: 18,
                    family: 'Arial, sans-serif', // Custom font for tooltip title
                    weight: 'normal',
                },
                padding: 10,
                color: 'black',
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const presentDays = chartData[context.dataIndex];
                        const percentage = context.raw.toFixed(2);
                        return `(${percentage}%) present`;
                    },
                },
                bodyColor: 'white', // Tooltip text in white
                titleColor: 'white',
            },
        },
        scales: {
            x: {
                ticks: {
                    color: 'black', // X-axis labels in white
                },
                grid: {
                    display: false, // Remove X-axis gridlines
                },
                font: {
                    weight: '100', // Light font for X-axis ticks
                    family: 'Arial, sans-serif', // Custom font for tooltip title
                    weight: 'normal', // Custom font for tooltip title

                },
            },
            y: {
                beginAtZero: true,
                max: 100,
                title: {
                    display: true,
                    text: 'Attendance (%)',
                    color: 'black',
                },
                ticks: {
                    color: 'black', // Y-axis labels in white
                },
                grid: {
                    display: false, // Remove Y-axis gridlines
                },
                font: {
                    family: 'Arial, sans-serif', // Custom font for tooltip title
                    // Light font for X-axis ticks
                },
            },
        },
    };
    const customStyles = {
        control: (base, state) => ({
          ...base,
          width: '200px',
          
          borderRadius: '8px',
          padding:'0px',
          borderColor: state.isFocused ? 'gray' : '#4a90e2',
          boxShadow: state.isFocused ? '0 0 5px rgba(128, 128, 128, 0.5)' : 'none',
        }),
        menu: (base) => ({
          ...base,
          maxHeight: '250px', // Limit dropdown height
          width: '200px',
          overflowY: 'auto', // Enable scrolling
        }),
        menuList: (base) => ({
          ...base,
          color: 'black',
          padding: 0,
          '::-webkit-scrollbar': {
            width: '6px', // Customize scrollbar width
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: '#ccc', // Customize scrollbar color
            borderRadius: '4px',
          },
        }),
      };

    // const customStyles = {
    //     control: (provided) => ({
    //         ...provided,
    //         marginBottom: '20px',
    //         borderRadius: '8px',
    //         borderColor: '#4a90e2',
    //         boxShadow: '0 0 5px rgba(74, 144, 226, 0.5)',
    //     }),
    // };

    return (
        <div className="card shadow-sm border-0  " style={{position: 'relative',  borderRadius: '10px', padding: '2px' }}>
            <div className="card-body ">
                {/* Chart Section (75% width) */}
                <h2 className="card-title" style={{ color: 'black' }}> Students Attendance </h2>

                    <div className="chart-wrapper  " style={{ height: '280px', backgroundColor:'white',borderRadius: '10px', }}>
                        <div className="text-center mt-4" style={{ position: 'absolute', top: '10px', right: '10px'}}>
                            <Select
                                options={periodOptions}
                                defaultValue={periodOptions[0]}
                                onChange={(e) => setSelectedPeriod(e.value)}
                                styles={customStyles}
                            />
                        </div>
                        <div className="chart-wrapper p-2" style={{ height: '270px', backgroundColor:'white'}}>
                            <Bar data={data} options={options} />
                        </div>
                    </div>
                

                
            </div>
            
        </div>
    );
};



export default Attendance;
