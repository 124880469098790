import React, { useState,useEffect } from 'react';
import axios from 'axios';
import '../assets/css/Dashboard.css';
import data from '../assets/data.json';
import { toast } from 'react-toastify';
const AddStudentModal = ({ show, onClose, student,onSubmitSuccess }) => {
    //console.log("onStudentAdded",student);
    
    
    
    const [editStudent, setEditStudent] = useState({
        personal_info: {
            regid: '',
            first_name: '',
            last_name: '',
            dob: '',
            gender: '',
            address: '',
            religion: '',
            aadhar: '',
            phone_number: '',
            whatsapp_number: '',
            blood_group: ''
        },
        academic_info: {
            school_id: '',
            roll_no: '',
            class: '',
            section: '',
            academic_year: `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`,
            enrollment_date: '',
            concession_amount: '',
            admission_fee: ''
        },
        parent_guardian_info: {
            father_name: '',
            mother_name: '',
            emergency_contact: ''
        }
    });
    //console.log("editStudent",editStudent);
    

    const [errors, setErrors] = useState('');
    const [showPreview, setShowPreview] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [API_PARENT_LINK, setApiParent] = useState('');
    useEffect(() => {
        setApiParent(data.API_PARENT_LINK);
    }, []);
    

    useEffect(() => {
        if (student) {
            setEditStudent({
                personal_info: {
                    regid: student.regid || '',
                    first_name: student.first_name || '',
                    last_name: student.last_name || '',
                    dob: student.dob || '',
                    gender: student.gender || '',
                    address: student.address || '',
                    religion: student.religion || '',
                    aadhar: student.aadhar || '',
                    phone_number: student.phone_number || '',
                    whatsapp_number: student.whatsapp_number || '',
                    blood_group: student.blood_group || ''
                },
                academic_info: {
                    school_id: student.school_id || '',
                    roll_no: student.roll_no || '',
                    class: student.class || '',
                    section: student.section || '',
                    academic_year: student.academic_year || '',
                    enrollment_date: student.enrollment_date || '',
                    concession_amount: student.concession_amount || '',
                    admission_fee: student.admission_fee || ''
                },
                parent_guardian_info: {
                    father_name: student.father_name || '',
                    mother_name: student.mother_name || '',
                    emergency_contact: student.emergency_contact || ''
                }
            });
        }
    }, [student]);

    const validateField = (name, value) => {
        let error = '';

        switch (name) {
            case 'personal_info.first_name':
            case 'personal_info.last_name':
            // case 'parent_guardian_info.father_name':

            // case 'parent_guardian_info.mother_name':
                if (!value) error = 'This field cannot be empty';
                else if (value.length < 2) error = 'Minimum 2 characters required';
                break;

            case 'personal_info.gender':
                if (!value) error = 'Gender cannot be empty';
                
                
                //else if (!['male', 'female'].includes(value)) error = 'Invalid gender';
                break;

            case 'personal_info.dob':
                if (!value) error = 'DOB cannot be empty';
                // else if (!/^\d{2}\-\d{2}\-\d{4}$/.test(value)) error = 'Format must be DD/MM/YYYY';
                break;

            // case 'personal_info.address':
            //     //if (!value) error = 'Address cannot be empty';
            //      if (value.length < 5) error = 'Minimum 5 characters required';
            //     break;

            case 'personal_info.phone_number':
            //case 'parent_guardian_info.emergency_contact':
                if (!value) error = 'This field cannot be empty';
                else if (!/^\d{10}$/.test(value)) error = 'Must be exactly 10 digits';
                break;

            case 'personal_info.whatsapp_number':
                //if (!value) error = 'This field cannot be empty';
                if (value && !/^\d{10}$/.test(value)) error = 'Must be exactly 10 digits if provided';
                break;

            // case 'personal_info.religion':
            //     if (!value) error = 'Religion cannot be empty';
            //     // else if (!['Islam', 'Hinduism', 'Buddhist', 'Christian', 'Sikh', 'Jain', 'Other'].includes(value))
            //     //     error = 'Invalid religion';
            //     break;

            // case 'personal_info.blood_group':
            //     if (!value) error = 'Blood group cannot be empty';
            //     // else if (!['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'].includes(value))
            //     //     error = 'Invalid blood group';
            //     break;

            case 'personal_info.aadhar':
                if (value && !/^\d{12}$/.test(value)) error = 'Must be exactly 12 digits if provided';
                break;

            // case 'academic_info.roll_no':
            //     if (!value) error = 'Roll number cannot be empty';
            //     //else if (value.length < 2) error = 'Minimum 2 characters required';
            //     break;

            case 'academic_info.class':
                if (!value) error = 'Class cannot be empty';
                // else if (!['Nursery', 'LKG', 'UKG', '1', '2', '3', 'Class 4', 'Class 5', 'Class 6', 'Class 7', 'Class 8', 'Class 9', 'Class 10', 'Class 11', 'Class 12'].includes(value))
                //     error = 'Invalid class';
                break;

            case 'academic_info.section':
                if (!value) error = 'Section cannot be empty';
                // else if (!['A', 'B', 'C', 'D'].includes(value)) error = 'Invalid section';
                break;
            case 'academic_info.school_id':
                if (!value) error = 'School ID cannot be empty';

                break;

            case 'academic_info.academic_year':
                if (!value) error = 'Academic year cannot be empty';
                // else if (!['2023-2024', '2024-2025', '2025-2026'].includes(value)) error = 'Invalid academic year';
                break;

            // case 'academic_info.admission_fee':
            //     if (!value) error = 'Admission fee cannot be empty';
            //     else if (isNaN(value) || parseFloat(value) <= 0) error = 'Invalid amount';
            //     break;

            // case 'academic_info.concession_amount':
            //     if (value && (isNaN(value) || parseFloat(value) < 0)) error = 'Invalid amount';
            //     break;

            default:
                break;
        }

        return error;
    };
    
    // const handleInputChange = (e) => {
    //     setEditStudent({
    //         ...editStudent,
    //         [e.target.name]: e.target.value
    //     });
    // };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const [section, field] = name.split('.');
        if (name === "personal_info.address" || name==="personal_info.first_name" || name === "personal_info.last_name") {
            const validCharacters = /^[a-zA-Z0-9\s.,-]*$/;
            if (!validCharacters.test(value)) return; // Prevent invalid characters
        }
        

        const keys = name.split('.');
        setEditStudent(prevState => ({
            ...prevState,
            [section]: { ...prevState[section], [field]: value }
        }));
        

        setErrors({ ...errors, [name]: '' });
    };
    const validateForm = () => {
        const newErrors = {};

        Object.keys(editStudent).forEach(section => {
            Object.keys(editStudent[section]).forEach(field => {
                const fieldName = `${section}.${field}`;
                const error = validateField(fieldName, editStudent[section][field]);
                //console.log("error",error);
                
                if (error) newErrors[fieldName] = error;
            });
        });


        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const convertValuesToString = (obj) => {
        return Object.fromEntries(
            Object.entries(obj).map(([key, value]) => {
                if (typeof value === 'object' && value !== null) {
                    return [key, convertValuesToString(value)]; // Recursive call for nested objects
                } else if ((key === 'dob' && typeof value === 'string') || (key === 'enrollment_date' && typeof value === 'string')) {
                    // Check if the date is in dd/mm/yyyy format
                    const [day, month, year] = value.split('/');
                    if (day && month && year) {
                        return [key, `${year}-${month}-${day}`]; // Convert to yyyy-mm-dd format
                    }
                }
                else if (key === 'admission_fee' || key === 'concession_amount') {
                    // Keep admission_fee and concession_amount as numbers
                    return [key, value ? parseFloat(value) : 0];
                }
                return [key, String(value)]; // Convert value to string
            })
        );
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        // console.log("Form Submitted"); 
        // setError('');
        // setSuccessMessage('');
        //validateForm();
        const regid = student.regid; // Extract regid from the student object

    // Update editStudent state with the new regid
        setEditStudent(prevState => ({
            ...prevState,
            personal_info: {
                ...prevState.personal_info,
                regid: regid,
            }
        }));
        //console.log("regid",editStudent);
        

        try {
            // https://danisheazam.pythonanywhere.com/
            const sessionKey = encodeURIComponent(localStorage.getItem('session_key'));
            const sessionUser = localStorage.getItem('session_user');
            const school_id = localStorage.getItem('school_id');
            //console.log("API started: ");
            if (!sessionKey || sessionKey === 'null' || !sessionUser) {
                setErrors("Session key or user is missing. Please log in again.");
                return;
             }
             if (!API_PARENT_LINK) {
                setErrors("API endpoint not available");
                return;
             }
            //console.log("API parent link : ", API_PARENT_LINK);
            const apiUrl = `${API_PARENT_LINK}Students/?student_id=${student.student_id}&session_key=${sessionKey}&session_user=${sessionUser}&school_id=${school_id}`;
            //console.log("API url : ", apiUrl);
            const studentDataAsStrings = convertValuesToString(editStudent);
            //console.log(studentDataAsStrings);
            const response = await axios.put(apiUrl,studentDataAsStrings);

            if (response.status === 200) {
                 toast.success('Student added successfully!');
                setSuccessMessage('Student updated successfully!');
                onSubmitSuccess();
                onClose();
             } 
            
        } catch (error) {
            setErrors(error.response?.data?.message || 'Error updating student');
            //console.log("API Error: ", error);
        }
    };

    const handleNext = () => {
        // Add any validation logic here if needed
        if (!validateForm()) {
            return;

        }
        setShowPreview(true);
    };

    const handleBack = () => {
        setShowPreview(false);
    };
    // Calculate final fee breakdown
    const admissionFee = parseFloat(editStudent.academic_info.admission_fee) || 0;
    const concessionAmount = parseFloat(editStudent.academic_info.concession_amount) || 0;
    const finalAmount = admissionFee - concessionAmount;

    return (
        <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" aria-labelledby="addStudentModalLabel" aria-hidden={!show}>
            {!showPreview && (
            <div className="modal-dialog modal-lg modal-dialog-centered"> {/* Added modal-lg and modal-dialog-centered for better layout */}
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="addStudentModalLabel">Update Student</h5>
                        <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button> {/* X button for closing */}
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleFormSubmit}>
                            <h5 className='mb-4'>Personal Information</h5>
                            <div className="row">
                                <div className="col-md-6 mb-3 floating-label">
                                    <input
                                        type="text"
                                        className={`form-control ${editStudent.personal_info.first_name ? 'has-value' : ''}`}
                                        name="personal_info.first_name"
                                        value={editStudent.personal_info.first_name}
                                        onChange={handleInputChange}
                                        pattern="[a-zA-Z0-9\s.,-]*"
                                        
                                    />
                                    <label className="form-label">First Name*</label>
                                    {errors['personal_info.first_name'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['personal_info.first_name']}</p>}

                                </div>
                                <div className="col-md-6 mb-3 floating-label">
                                    <input
                                        type="text"
                                        className={`form-control ${editStudent.personal_info.last_name ? 'has-value' : ''}`}
                                        name="personal_info.last_name"
                                        value={editStudent.personal_info.last_name}
                                        onChange={handleInputChange}
                                        pattern="[a-zA-Z0-9\s.,-]*"
                                        
                                    />
                                    <label className="form-label">Last Name*</label>
                                    {errors['personal_info.last_name'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['personal_info.last_name']}</p>}
                                </div>
                                <div className="col-md-6 mb-3 floating-label">
                                    <input
                                        type="text"
                                        className={`form-control ${editStudent.personal_info.address ? 'has-value' : ''}`}
                                        name="personal_info.address"
                                        value={editStudent.personal_info.address}
                                        onChange={handleInputChange}
                                        pattern="[a-zA-Z0-9\s.,-]*"
                                        
                                    />
                                    <label className="form-label">Address</label>
                                    {errors['personal_info.address'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['personal_info.address']}</p>}
                                </div>
                                <div className="col-md-6 mb-3 floating-label">
                                    <input
                                        type="text"
                                        className={`form-control ${editStudent.personal_info.aadhar ? 'has-value' : ''}`}
                                        name="personal_info.aadhar"
                                        value={editStudent.personal_info.aadhar}
                                        onChange={handleInputChange}
                                        
                                    />
                                    <label className="form-label">Aadhar Card</label>
                                    {errors['personal_info.aadhar'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['personal_info.aadhar']}</p>}
                                </div>
                                <div className="col-md-6 mb-3 floating-label">
                                    <input
                                        type="text"
                                        className={`form-control ${editStudent.personal_info.phone_number ? 'has-value' : ''}`}
                                        name="personal_info.phone_number"
                                        value={editStudent.personal_info.phone_number}
                                        onChange={handleInputChange}
                                        
                                    />
                                    <label className="form-label">Phone Number*</label>
                                    {errors['personal_info.phone_number'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['personal_info.phone_number']}</p>}
                                </div>
                                <div className="col-md-6 mb-3 floating-label">
                                    <input
                                        type="text"
                                        className={`form-control ${editStudent.personal_info.whatsapp_number ? 'has-value' : ''}`}
                                        name="personal_info.whatsapp_number"
                                        value={editStudent.personal_info.whatsapp_number}
                                        onChange={handleInputChange}
                                        
                                    />
                                    <label className="form-label">WhatsApp Number</label>
                                    {errors['personal_info.whatsapp_number'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['personal_info.whatsapp_number']}</p>}
                                </div>

                                <div className="col-md-6 mb-3 floating-label">
                                    <input
                                        type="text"
                                        className={`form-control ${editStudent.personal_info.dob ? 'has-value' : ''}`}
                                        name="personal_info.dob"
                                        value={editStudent.personal_info.dob ? new Date(editStudent.personal_info.dob).toISOString().split('T')[0] : ''}
                                        onChange={handleInputChange}
                                        onFocus={(e) => e.target.type = 'date'}
                                        onBlur={(e) => e.target.type = 'text'}
                                        placeholder=" "
                                        
                                    />
                                    <label className="form-label">Date of Birth*</label>
                                    {errors['personal_info.dob'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['personal_info.dob']}</p>}
                                </div>
                                <div className="col-md-6 mb-3 floating-label">
                                    <select
                                        className={`form-control ${editStudent.personal_info.gender ? 'has-value' : ''}`}
                                        name="personal_info.gender"
                                        value={editStudent.personal_info.gender}
                                        onChange={handleInputChange}
                                        
                                    >
                                        <option value="" disabled hidden> </option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                    <label className="form-label">Gender*</label>
                                    {errors['personal_info.gender'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['personal_info.gender']}</p>}
                                </div>
                                <div className="col-md-6 mb-3 floating-label">
                                    <select
                                        className={`form-control ${editStudent.personal_info.religion ? 'has-value' : ''}`}
                                        name="personal_info.religion"
                                        value={editStudent.personal_info.religion}
                                        onChange={handleInputChange}
                                        
                                    >
                                        <option value="" disabled hidden> </option>
                                        <option value="Islam">Islam</option>
                                            <option value="Hinduism">Hinduism</option>
                                            <option value="Buddhist">Buddhist</option>
                                            <option value="Christian">Christian</option>
                                            <option value="Sikh">Sikh</option>
                                            <option value="Jain">Jain</option>
                                            <option value="Other">Other</option>
                                    </select>
                                    <label className="form-label">Religion</label>
                                    {errors['personal_info.religion'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['personal_info.religion']}</p>}
                                </div>
                                <div className="col-md-6 mb-3 floating-label">
                                    <select
                                        className={`form-control ${editStudent.personal_info.blood_group ? 'has-value' : ''}`}
                                        name="personal_info.blood_group"
                                        value={editStudent.personal_info.blood_group}
                                        onChange={handleInputChange}
                                        
                                    >
                                        <option value="" disabled hidden> </option>
                                        <option value="A+">A+</option>
                                            <option value="A-">A-</option>
                                            <option value="B+">B+</option>
                                            <option value="B-">B-</option>
                                            <option value="AB+">AB+</option>
                                            <option value="AB-">AB-</option>
                                            <option value="O+">O+</option>
                                            <option value="O-">O-</option>
                                    </select>
                                    <label className="form-label">Blood Group</label>
                                    {errors['personal_info.blood_group'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['personal_info.blood_group']}</p>}
                                </div>
                            </div>
                            <hr></hr>
                            {/* Academic Information Section */}
                            <h5 className='mb-4'>Academic Information</h5>

                            
                            <div className="row">
                                
                                <div className="col-md-6 mb-3 floating-label">
                                    <input
                                        type="text"
                                        className={`form-control ${editStudent.academic_info.roll_no ? 'has-value' : ''}`}
                                        name="academic_info.roll_no"
                                        value={editStudent.academic_info.roll_no}
                                        onChange={handleInputChange}
                                        
                                        
                                        placeholder=" " // Placeholder for the floating label effect
                                    />
                                    <label className="form-label">Roll Number</label>
                                    {errors['academic_info.roll_no'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['academic_info.roll_no']}</p>}
                                </div>
                                <div className="col-md-6 mb-3 floating-label">
                                    <input
                                        type="text"
                                        className={`form-control ${editStudent.academic_info.concession_amount ? 'has-value' : ''}`}
                                        name="academic_info.concession_amount"
                                        value={editStudent.academic_info.concession_amount}
                                        onChange={handleInputChange}
                                        
                                        
                                        placeholder=" " // Placeholder for the floating label effect
                                    />
                                    <label className="form-label">Concession Amount</label>
                                    {errors['academic_info.concession_amount'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['academic_info.concession_amount']}</p>}
                                </div>
                                <div className="col-md-6 mb-3 floating-label">
                                    <select
                                        className={`form-control ${editStudent.academic_info.class ? 'has-value' : ''}`}
                                        name="academic_info.class"
                                        value={editStudent.academic_info.class}
                                        onChange={handleInputChange}
                                        
                                    >
                                        <option value="" disabled hidden> </option>
                                        <option value="Nursery">Nursery</option>
                                        <option value="LKG">LKG</option>
                                        <option value="UKG">UKG</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                    <label className="form-label">Class*</label>
                                    {errors['academic_info.class'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['academic_info.class']}</p>}
                                </div>
                                <div className="col-md-6 mb-3 floating-label">
                                    <select
                                        className={`form-control ${editStudent.academic_info.section ? 'has-value' : ''}`}
                                        name="academic_info.section"
                                        value={editStudent.academic_info.section}
                                        onChange={handleInputChange}
                                        
                                    >
                                        <option value="" disabled hidden> </option>
                                        <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                            <option value="D">D</option>
                                    </select>
                                    <label className="form-label">Section*</label>
                                    {errors['academic_info.section'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['academic_info.section']}</p>}
                                </div>
                                {/* <div className="col-md-6 mb-3 floating-label">
                                    <select
                                        className={`form-control ${editStudent.academic_info.academic_year ? 'has-value' : ''}`}
                                        name="academic_info.academic_year"
                                        value={editStudent.academic_info.academic_year}
                                        onChange={handleInputChange}
                                        
                                    >
                                        <option value="" disabled hidden> </option>
                                        <option value="2023-2024">2023-2024</option>
                                            <option value="2024-2025">2024-2025</option>
                                            <option value="2025-2026">2025-2026</option>
                                    </select>
                                    <label className="form-label">Academic Year*</label>
                                    {errors['academic_info.academic_year'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['academic_info.academic_year']}</p>}
                                </div> */}

                                <div className="col-md-6 mb-3 floating-label">
                                    <input
                                        type="text"
                                        className={`form-control ${editStudent.academic_info.enrollment_date ? 'has-value' : ''}`}
                                        name="academic_info.enrollment_date"
                                        value={editStudent.academic_info.enrollment_date ? new Date(editStudent.academic_info.enrollment_date).toISOString().split('T')[0] : ''}
                                        onChange={handleInputChange}
                                        onFocus={(e) => e.target.type = 'date'}
                                        onBlur={(e) => e.target.type = 'text'}
                                        placeholder=" "
                                        
                                    />
                                    <label className="form-label">Enrollment Date</label>
                                    {errors['academic_info.enrollment_date'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['academic_info.enrollment_date']}</p>}
                                </div>

                                <div className="col-md-6 mb-3 floating-label">
                                    <select
                                        className={`form-control ${editStudent.academic_info.school_id ? 'has-value' : ''}`}
                                        name="academic_info.school_id"
                                        value={editStudent.academic_info.school_id}
                                        onChange={handleInputChange}
                                        
                                    >
                                        <option value="" disabled hidden> </option>
                                        <option value="1">Morning Bells - Senior Wing</option>
                                            <option value="2">Morning Bells - Junior Wing</option>
                                    </select>
                                    <label className="form-label">School ID</label>
                                    {errors['academic_info.school_id'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['academic_info.school_id']}</p>}
                                </div>
                                {/* <div className="col-md-6 mb-3 floating-label">
                                    <input
                                        type="text"
                                        className={`form-control ${editStudent.academic_info.admission_fee ? 'has-value' : ''}`}
                                        name="academic_info.admission_fee"
                                        value={editStudent.academic_info.admission_fee}
                                        onChange={handleInputChange}
                                        
                                        
                                        placeholder=" " // Placeholder for the floating label effect
                                    />
                                    <label className="form-label">Admission Fee</label>
                                    {errors['academic_info.admission_fee'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['academic_info.admission_fee']}</p>}
                                </div> */}

                            </div>
                            <hr></hr>
                            {/* Parent/Guardian Information Section */}
                            <h5 className='mb-4'>Parent/Guardian Information</h5>

                            <div className="row">
                                

                                <div className="col-md-6 mb-3 floating-label">
                                    <input
                                        type="text"
                                        className={`form-control ${editStudent.parent_guardian_info.father_name ? 'has-value' : ''}`}
                                        name="parent_guardian_info.father_name"
                                        value={editStudent.parent_guardian_info.father_name}
                                        onChange={handleInputChange}
                                        
                                    />
                                    <label className="form-label">Father's Name</label>
                                    {errors['parent_guardian_info.father_name'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['parent_guardian_info.father_name']}</p>}
                                </div>
                                <div className="col-md-6 mb-3 floating-label">
                                    <input
                                        type="text"
                                        className={`form-control ${editStudent.parent_guardian_info.mother_name ? 'has-value' : ''}`}
                                        name="parent_guardian_info.mother_name"
                                        value={editStudent.parent_guardian_info.mother_name}
                                        onChange={handleInputChange}
                                        
                                    />
                                    <label className="form-label">Mother's Name</label>
                                    {errors['parent_guardian_info.mother_name'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['parent_guardian_info.mother_name']}</p>}
                                </div>
                                <div className="col-md-6 mb-3 floating-label">
                                    <input
                                        type="text"
                                        className={`form-control ${editStudent.parent_guardian_info.emergency_contact ? 'has-value' : ''}`}
                                        name="parent_guardian_info.emergency_contact"
                                        value={editStudent.parent_guardian_info.emergency_contact}
                                        onChange={handleInputChange}
                                        
                                    />
                                    <label className="form-label">Emergency Contact</label>
                                    {errors['parent_guardian_info.emergency_contact'] && <p className="text-danger" style={{ fontSize: '0.775rem' }}>{errors['parent_guardian_info.emergency_contact']}</p>}
                                </div>
                            </div>

                           
                            <div className="d-flex justify-content-end">
                                <button type="button" className="btn btn-secondary me-2" onClick={onClose}>Close</button>
                                <button type="button" className="btn btn-primary" onClick={handleNext}>Next </button>
                            </div>
                        </form>
{/* 
                        {error && <p className="text-danger mt-2">{error}</p>}
                        {successMessage && <p className="text-success mt-2">{successMessage}</p>} */}
                    </div>
                </div>
            </div>)}

            {/* Preview Modal */}
            {showPreview && (
                <div className="modal show d-block " tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm  Information For {student.regid}</h5>
                                {/* <button
                                    type="button"
                                    onClick={onClose}
                                    className="btn btn-link text-dark"
                                    style={{ padding: 0 }}
                                    aria-label="Close"
                                >
                                    <i className="bi bi-x-lg" style={{ fontSize: '24px' }}></i>
                                </button>   */}
                            </div>
                            <div className="modal-body p-4">
                                <div className="row">
                                    {/* Personal Information Column */}
                                    <div className="col-md-6">
                                        <h4>Personal Information</h4>
                                        <p><strong>Name:</strong> {editStudent.personal_info.first_name}&nbsp;{editStudent.personal_info.last_name}</p>

                                        <p><strong>Address:</strong> {editStudent.personal_info.address}</p>
                                        <p><strong>Aadhar Card:</strong> {editStudent.personal_info.aadhar || 'N/A'}</p>
                                        <p><strong>Phone Number:</strong> {editStudent.personal_info.phone_number}</p>
                                        <p><strong>WhatsApp Number:</strong> {editStudent.personal_info.whatsapp_number}</p>
                                        <p><strong>Date of Birth:</strong> {editStudent.personal_info.dob}</p>
                                        <p><strong>Gender:</strong> {editStudent.personal_info.gender}</p>
                                        <p><strong>Religion:</strong> {editStudent.personal_info.religion}</p>
                                        <p><strong>Blood Group:</strong> {editStudent.personal_info.blood_group}</p>
                                    </div>

                                    {/* Academic and Parent Information Column */}
                                    <div className="col-md-6">
                                        <h4>Academic Information</h4>
                                        <p><strong>Roll Number:</strong> {editStudent.academic_info.roll_no}</p>
                                        <p><strong>Class:</strong> {editStudent.academic_info.class}</p>
                                        <p><strong>Section:</strong> {editStudent.academic_info.section}</p>
                                        <p><strong>Academic Year:</strong> {editStudent.academic_info.academic_year}</p>
                                        <p><strong>Enrollment Date:</strong> {editStudent.academic_info.enrollment_date}</p>
                                        <p><strong>School ID:</strong> {editStudent.academic_info.school_id === '1'
                                            ? "Morning Bells - Senior Wing"
                                            : "Morning Bells - Junior Wing"}</p>



                                        <h4>Parent/Guardian Information</h4>
                                        <p><strong>Father's Name:</strong> {editStudent.parent_guardian_info.father_name}</p>
                                        <p><strong>Mother's Name:</strong> {editStudent.parent_guardian_info.mother_name}</p>
                                        <p><strong>Emergency Contact:</strong> {editStudent.parent_guardian_info.emergency_contact}</p>
                                    </div>
                                </div>

                                {/* <hr></hr> */}

                                {/* Admission Fee Breakdown */}
                                {/* <h4>Admission Fee Breakdown</h4>
                                <p><strong>Total Fee:</strong> ₹{admissionFee.toFixed(2)}</p>
                                <p><strong>Concession Amount:</strong> ₹{concessionAmount.toFixed(2)}</p>
                                <p><strong>Final Amount:</strong> ₹{finalAmount.toFixed(2)}</p> */}
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleBack}>Back</button>
                                <button type="button" className="btn btn-primary" onClick={handleFormSubmit}>Submit</button>
                                <button type="button" className="btn btn-danger" onClick={onClose}>Cancel</button>
                            </div>
                            {errors.api && <p className="text-danger mt-2">{errors.api}</p>}
                        </div>
                    </div>

                </div>
            )}
        </div>

    );
};

export default AddStudentModal;
