import React, { useEffect, useState } from 'react';
import axios from 'axios';
import data from '../assets/data.json';
import 'bootstrap-icons/font/bootstrap-icons.css';

function Enquiry() {
    const [inquiries, setInquiries] = useState([]);
    const [filteredInquiries, setFilteredInquiries] = useState([]);
    const [error, setError] = useState('');
    const [API_PARENT_LINK, setApiParent] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    // Load the API URLs from data.json once
    useEffect(() => {
        setApiParent(data.API_PARENT_LINK);
    }, []);

    useEffect(() => {
        if (API_PARENT_LINK) {
            fetchInquiries();
        }
    }, [API_PARENT_LINK]);

    const fetchInquiries = async () => {
        try {
            if (!API_PARENT_LINK) {
                throw new Error("API endpoint not available");
            }
            const school_id = localStorage.getItem('school_id');
            const apiUrl = `${API_PARENT_LINK}AdmissionsInquiry/?inquiry_id=-1&school_id=${school_id}`;
            const response = await axios.get(apiUrl);
            setInquiries(response.data.inquiry);
            setFilteredInquiries(response.data.inquiry); // Initialize filtered data
        } catch (error) {
            console.error('Error fetching inquiries:', error);
        }
    };

    // Handle delete action
    const handleDelete = async (inquiryId) => {
        console.log(inquiryId);
        //https://danisheazam.pythonanywhere.com/AdmissionsInquiry/?inquiry_id=1
        
        try {
            if (!API_PARENT_LINK) {
                throw new Error("API endpoint not available");
            }
            const school_id = localStorage.getItem('school_id');
            const apiUrl = `${API_PARENT_LINK}AdmissionsInquiry/?inquiry_id=${inquiryId}&school_id=${school_id}`;
            console.log(apiUrl);
            
            await axios.delete(apiUrl);
            setInquiries(inquiries.filter(inquiry => inquiry.inquiry_id !== inquiryId));
            setFilteredInquiries(filteredInquiries.filter(inquiry => inquiry.inquiry_id !== inquiryId));
        } catch (error) {
            console.error('Error deleting inquiry:', error);
            setError('Failed to delete inquiry. Please try again.');
        }
    };

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        setFilteredInquiries(
            inquiries.filter(inquiry =>
                inquiry.student_name.toLowerCase().includes(term) ||
                inquiry.class_interested.toLowerCase().includes(term) ||
                inquiry.inquiry_date.toLowerCase().includes(term) ||
                inquiry.contact_number.toString().includes(term) ||
                inquiry.inquiry_id.toString().includes(term)||
                inquiry.how_they_came_to_know.toLowerCase().includes(term) 
            )
        );
    };

    return (
        <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px' }}>
            <div className="d-flex align-items-center mb-3">
                <i className="bi bi-building me-2"></i>
                <h2 style={{ fontSize: '1.5rem', margin: 0 }}>School Enquiries</h2>
            </div>
            <div className="  col-md-6 mb-3  ">
                <div className="input-group ">
                    <span className="input-group-text bg-white border-0"  style={{ 
                borderRadius: '2rem 0 0 2rem',
                padding: '0.5rem 0.75rem' // Adjust padding for better alignment
            }}>
                        <i className="bi bi-search" style={{ color: '#6c757d' }}></i>
                    </span>
                    <input
                        type="text"
                        className="form-control shadow-sm p-2 "
                        placeholder="Search by student name, class, date, contact number, or ID"
                        value={searchTerm}
                        onChange={handleSearch}
                        style={{
                            border: '1px solid #dee1e3', // Bootstrap primary border color
                            borderRadius: '2rem ', // Rounded corners for the input field
                            fontSize: '0.9rem', // Smaller font size
                             paddingLeft: '1.5rem'
                        }}
                        onFocus={(e) => (e.target.style.borderColor = 'royalblue')} // Change border color on focus
                        onBlur={(e) => (e.target.style.borderColor = '#dee1e3')}
                    />
                </div>
            </div>
            {error && <p className="text-danger">{error}</p>}
            <div style={{ height: '500px', overflowY: 'auto' }}>
                <table className="table table-bordered table-hover">
                    <thead className="table-dark bg-dark border-light " style={{ position: 'sticky', top: -1, backgroundColor: '#f8f9fa' , zIndex: '1' }}>
                        <tr>
                            <th style={{maxWidth: '100px'}}>ID</th>
                            <th style={{textAlign: 'center'}}>Name</th>
                            <th>Date</th>
                            <th>Source</th>
                            <th style={{ maxWidth: '150px',textAlign: 'center'}}>Contact Number</th>
                            <th>Class</th>
                            <th>Comments</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredInquiries.length > 0 ? (
                            filteredInquiries.map(inquiry => (
                                <tr key={inquiry.inquiry_id} style={{ fontSize: '0.9rem', lineHeight: '1.2' }}>
                                    <td style={{ textAlign: 'center',maxWidth: '50px',wordWrap: 'break-word', whiteSpace: 'normal' }}>{inquiry.inquiry_id}</td>
                                    <td style={{textAlign: 'center', minWidth: '150px',wordWrap: 'break-word', whiteSpace: 'normal' }}>{inquiry.student_name}</td>
                                    <td style={{ textAlign: 'center',maxWidth: '100px',wordWrap: 'break-word', whiteSpace: 'normal' }}>{inquiry.inquiry_date}</td>
                                    <td style={{ textAlign: 'center',maxWidth: '100px',wordWrap: 'break-word', whiteSpace: 'normal' }}>{inquiry.how_they_came_to_know}</td>
                                    <td style={{ textAlign: 'center',maxWidth: '100px',wordWrap: 'break-word', whiteSpace: 'normal' }}>{inquiry.contact_number}</td>
                                    <td style={{textAlign: 'center', maxWidth: '50px',wordWrap: 'break-word', whiteSpace: 'normal' }}>{inquiry.class_interested}</td>
                                    <td style={{textAlign: 'center', maxWidth: '200px',wordWrap: 'break-word', whiteSpace: 'normal' }}>{inquiry.additional_comments}</td>
                                    <td>
                                        <button
                                            className="btn btn-danger btn-sm"
                                            onClick={() => handleDelete(inquiry.inquiry_id)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center">
                                    No enquiries found.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Enquiry;
