import React from 'react';
import { Navigate ,Outlet} from 'react-router-dom';

const PrivateRoute = ({ element }) => {
    const isAuthenticated = !!localStorage.getItem('session_key'); // Check if the user is authenticated

    // Render the element as JSX
    return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
