
import React, { useState, useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2'; // Import Bar chart from charts.js
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AttendanceBarChart from './AttendanceBarChart'
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    BarElement,
} from 'chart.js';

ChartJS.register(
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    BarElement
);

// Sample Data with Total Days and Present Days
const sampleData = {
    "This Month": [20, 18, 19, 16, 17, 22, 21, 19, 20, 18], // Present days out of 30
    "Last Month": [15, 17, 14, 12, 18, 20, 19, 13, 15, 16],
    "This Week": [5, 6, 4, 5, 7, 6, 6, 5, 7, 6], // Present days out of 7
    "Last Week": [3, 5, 4, 2, 6, 7, 5, 4, 5, 3],
    "This Year": [180, 190, 175, 160, 185, 200, 192, 178, 195, 210], // Present days out of 220
    "Last Year": [170, 180, 168, 155, 188, 185, 180, 160, 185, 190],
};

const totalDaysData = {
    "This Month": 30,
    "Last Month": 30,
    "This Week": 7,
    "Last Week": 7,
    "This Year": 220,
    "Last Year": 220,
};




const AttendanceTab = () => {
    // Sample data for demonstration
    const sampleData = [
        { name: 'John Doe', class: '1', date: '2024-10-01', status: 'Present' },
        { name: 'Jane Smith', class: '2', date: '2024-10-01', status: 'Absent' },
        { name: 'Sam Wilson', class: '3', date: '2024-10-02', status: 'Present' },
        { name: 'Anna Taylor', class: '2', date: '2024-10-03', status: 'Present' },
        { name: 'Tom Hanks', class: '1', date: '2024-10-01', status: 'Absent' },
    ];

    const [searchQuery, setSearchQuery] = useState(''); // State for search input
    const [filteredData, setFilteredData] = useState(sampleData); // State for filtered data

    // Effect to filter the data based on the search query
    useEffect(() => {
        const lowercasedQuery = searchQuery.toLowerCase();
        const filtered = sampleData.filter(
            (record) =>
                record.name.toLowerCase().includes(lowercasedQuery) ||
                record.class.toString().includes(lowercasedQuery) ||
                record.date.includes(lowercasedQuery) ||
                record.status.toLowerCase().includes(lowercasedQuery)
        );
        setFilteredData(filtered);
    }, [searchQuery]);

    const [attendanceData, setAttendanceData] = useState({}); // For Graph
    const [lineChartData, setLineChartData] = useState({});
    const [detailedAttendance, setDetailedAttendance] = useState([]); // For table
    const [loading, setLoading] = useState(true);

    const [selectedPeriod, setSelectedPeriod] = useState("This Month");
    const [chartData, setChartData] = useState([]);

    // Update chart data based on the selected period
    useEffect(() => {
        const data = sampleData[selectedPeriod] || []; // Fallback to an empty array
        setChartData(data);
    }, [selectedPeriod]);

    const totalDays = totalDaysData[selectedPeriod] || 1;





    // Helper function to get last 6 months dynamically
    const getLast6Months = () => {
        const months = [];
        const now = new Date();
        for (let i = 5; i >= 0; i--) {
            const month = new Date(now.getFullYear(), now.getMonth() - i, 1).toLocaleString('default', { month: 'long' });
            months.push(month);
        }
        return months;
    };

    // Example data (Replace with actual API calls in production)
    useEffect(() => {
        setAttendanceData({
            labels: ['Class 1', 'Class 2', 'Class 3', 'Class 4', 'Class 5', 'Class 6', 'Class 7', 'Class 8', 'Class 9', 'Class 10', 'Class 11'],
            datasets: [
                {
                    label: 'Attendance Percentage',
                    backgroundColor: '#4caf50',
                    borderColor: '#388e3c',
                    borderWidth: 1,
                    hoverBackgroundColor: '#81c784',
                    hoverBorderColor: '#66bb6a',
                    data: [85, 78, 92, 80, 80, 50], // Example attendance percentages
                },
            ],
        });
        // Set Line chart data for last 6 months
        setLineChartData({
            labels: getLast6Months(),
            datasets: [
                {
                    label: 'Class 1',
                    data: [85, 88, 84, 90, 87, 89],
                    borderColor: '#FF5733',
                    tension: 0.4,
                    fill: false,
                },
                {
                    label: 'Class 2',
                    data: [78, 82, 80, 79, 76, 81],
                    borderColor: '#33FF57',
                    tension: 0.4,
                    fill: false,
                },
                {
                    label: 'Class 3',
                    data: [92, 95, 93, 94, 90, 92],
                    borderColor: '#3357FF',
                    tension: 0.4,
                    fill: false,
                },
                {
                    label: 'Class 4',
                    data: [85, 88, 84, 90, 87, 89],
                    borderColor: '#FFC300',
                    tension: 0.4,
                    fill: false,
                },
                {
                    label: 'Class 5',
                    data: [78, 82, 80, 79, 76, 81],
                    borderColor: '#DAF7A6',
                    tension: 0.4,
                    fill: false,
                },
                {
                    label: 'Class 6',
                    data: [92, 95, 93, 94, 90, 92],
                    borderColor: '#900C3F',
                    tension: 0.4,
                    fill: false,
                },
                {
                    label: 'Class 7',
                    data: [85, 88, 84, 90, 87, 89],
                    borderColor: 'green',
                    tension: 0.4,
                    fill: false,
                },
                {
                    label: 'Class 9',
                    data: [78, 82, 80, 79, 76, 81],
                    borderColor: 'yellow',
                    tension: 0.4,
                    fill: false,
                },
                {
                    label: 'Class 8',
                    data: [9, 94, 90, 44, 59, 20],
                    borderColor: 'pink',
                    tension: 0.4,
                    fill: false,
                },
            ],
        });



        setTimeout(() => {
            setDetailedAttendance([
                { name: 'John Doe', class: '1', date: '2024-10-01', status: 'Present' },
                { name: 'Jane Smith', class: '2', date: '2024-10-01', status: 'Absent' },
            ]);
            setLoading(false);
        }, 1000);
    }, []);
    if (loading) return <p>Loading Attendance Data...</p>;

    return (
        <div className="container-fluid mt-4">
            <div className="row">
                {/* Attendance Overview Cards */}
                <div className="col-md-3 mb-3">
                    <div className="card shadow-sm border-0">
                        <div className="card-body text-center">
                            <i className="bi bi-people-fill fs-1 text-success"></i>
                            <h5 className="mt-2">Overall Attendance</h5>
                            <p className="fs-4 mb-0">85%</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mb-3">
                    <div className="card shadow-sm border-0">
                        <div className="card-body text-center">
                            <i className="bi bi-bar-chart-fill fs-1 text-info"></i>
                            <h5 className="mt-2">Best Class</h5>
                            <p className="fs-4 mb-0">Class 3 - 92%</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mb-3">
                    <div className="card shadow-sm border-0">
                        <div className="card-body text-center">
                            <i className="bi bi-person-badge-fill fs-1 text-danger"></i>
                            <h5 className="mt-2">Lowest Attendance</h5>
                            <p className="fs-4 mb-0">Class 2 - 78%</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mb-3">
                    <div className="card shadow-sm border-0">
                        <div className="card-body text-center">
                            <i className="bi bi-person-badge-fill fs-1 text-danger"></i>
                            <h5 className="mt-2">Lowest Attendance</h5>
                            <p className="fs-4 mb-0">Class 2 - 78%</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Line Chart */}
            <div className="row mb-4">
                <div className="col-12">
                    <div className="card shadow-sm border-0">
                        <div className="card-body">
                            <h5 className="card-title" style={{ color: 'black' }}>Attendance Overview</h5>
                            <div className="chart-wrapper" style={{ height: '400px' }}>
                                <Line
                                    data={lineChartData}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        plugins: {
                                            legend: {
                                                display: true,
                                                position: 'top',
                                                color: 'black',
                                                labels: {
                                                    color: 'black',
                                                    usePointStyle: true, // Use points instead of rectangles
                                                    pointStyle: 'circle', // Specify the shape as circle
                                                },
                                            },
                                            tooltip: { enabled: true },
                                        },
                                        scales: {
                                            x: {
                                                title: { display: true, text: 'Months', color: 'black', }, grid: {
                                                    display: false, // Remove Y-axis gridlines

                                                },
                                                ticks: {
                                                    color: 'black', // X-axis labels in white
                                                },
                                            },
                                            y: {
                                                title: { display: true, text: 'Attendance (%)', color: 'black', },
                                                min: 0,
                                                max: 100,
                                                ticks: { stepSize: 10, color: 'black', },
                                                grid: {
                                                    display: false, // Remove Y-axis gridlines
                                                },

                                            },
                                        },
                                    }}
                                    height={300}
                                />
                            </div >
                        </div>
                    </div>
                </div>
            </div>


            {/* Attendance Bar Chart */}
            <div className="row">

                <div className="col-md-8">
                    <AttendanceBarChart />
                </div>


                {/* Detailed Attendance Table */}
                <div className="col-md-4">
                    <div className="card shadow-sm border-0">
                        <div className="card-body " style={{ height: '380px'}}>
                            {/* Search Bar */}
                            <input
                                type="text"
                                className="form-control mb-2 small-search-sm p-2"
                                placeholder="Search by student name, class, date, or status"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                style={{
                                    border: '1px solid #dee1e3', // Default Bootstrap primary border color
                                    borderRadius: '2rem', // Bootstrap rounded corners
                                    fontSize: '0.9rem' // Smaller font size
                                }}
                                onFocus={(e) => (e.target.style.borderColor = 'royalblue')} // Change border color on focus
                                onBlur={(e) => (e.target.style.borderColor = '#dee1e3')}
                            />

                            {/* Attendance Table */}
                            <div className="table-responsive">
                                <table className="table table-striped " style={{ tableLayout: 'fixed', width: '100%' }}>
                                    <thead >
                                        <tr >
                                            <th className=" " style={{ width: '25%', fontSize: '0.9rem',color:'black' }}>Student</th>
                                            <th  style={{width: '15%', fontSize: '0.9rem',color:'black' }}>Class</th>
                                            <th   style={{ width: '25%', fontSize: '0.9rem' ,color:'black'}}>Date</th>
                                            <th  style={{width: '25%',  fontSize: '0.9rem' ,color:'black'}}>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData.length > 0 ? (
                                            filteredData.map((record, index) => (
                                                <tr key={index}>
                                                    <td className="text-center" style={{ fontSize: '0.8rem' ,color:'black'}}>{record.name}</td>
                                                    <td className="text-center" style={{ fontSize: '0.8rem' ,color:'black'}}>{record.class}</td>
                                                    <td className="text-center" style={{ fontSize: '0.8rem',color:'black' }}>{record.date}</td>
                                                    <td className="text-center" style={{ fontSize: '0.8rem' ,color:'black'}}>{record.status}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4" className="text-center">
                                                    No Matching Records Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>





    );
};

export default AttendanceTab;
