import React, { useState,useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import data from '../assets/data.json';
import { toast } from 'react-toastify';


const ShowOverview = ({ student, onClose }) => {
  const attendanceChartRef = useRef(null);
  const resultsChartRef = useRef(null);
  const feesChartRef = useRef(null);
  //console.log(student);
  const sessionKey = encodeURIComponent(localStorage.getItem('session_key'));
    const sessionUser = localStorage.getItem('session_user');
    const school_id = localStorage.getItem('school_id');

    const [API_PARENT_LINK, setApiParent] = useState('');
    useEffect(() => {
        setApiParent(data.API_PARENT_LINK);
    }, []);


  useEffect(() => {
    // Attendance Chart
    const ctxAttendance = document.getElementById('attendanceChart').getContext('2d');
    if (attendanceChartRef.current) {
      attendanceChartRef.current.destroy();  // Destroy previous chart instance
    }
    attendanceChartRef.current = new Chart(ctxAttendance, {
      type: 'line',
      data: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        datasets: [{
          label: 'Attendance',
          data: [90, 85, 95, 80, 85, 92, 88],
          borderColor: '#007bff',
          fill: false,
        }],
      },
      options: {
        responsive: true, // Ensures the chart is responsive
        maintainAspectRatio: false,
      }
    });

    // Results Chart
    const ctxResults = document.getElementById('resultsChart').getContext('2d');
    if (resultsChartRef.current) {
      resultsChartRef.current.destroy();
    }
    resultsChartRef.current = new Chart(ctxResults, {
      type: 'bar',
      data: {
        labels: ['Math', 'Science', 'English', 'History', 'Geography'],
        datasets: [{
          label: 'Results',
          data: [85, 78, 90, 88, 92],
          backgroundColor: ['#007bff', '#28a745', '#ffc107', '#17a2b8', '#6f42c1'],
        }],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      }
    });

    // Sample data for each month (0 means no fees paid, replace with your actual data)
    const feesData = [100, 50, 75, 0, 100, 25, 0, 80, 90, 0, 50, 60]; // Example data for 12 months

    // Prepare the data and colors
    const feesChartData = feesData.map((amount) => amount > 0 ? amount : 0);
    const colors = feesData.map((amount) => amount > 0 ? '#28a745' : '#dc3545'); // Green if paid, red if due

    // Fees Chart
    const ctxFees = document.getElementById('feesChart').getContext('2d');
    if (feesChartRef.current) {
      feesChartRef.current.destroy();
    }

    feesChartRef.current = new Chart(ctxFees, {
      type: 'doughnut',
      data: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [{
          label: 'Fees',
          data: feesChartData,
          backgroundColor: colors,
        }],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },

    });

    // // Fees Chart
    // const ctxFees = document.getElementById('feesChart').getContext('2d');
    // if (feesChartRef.current) {
    //   feesChartRef.current.destroy();
    // }
    // feesChartRef.current = new Chart(ctxFees, {
    //   type: 'doughnut',
    //   data: {
    //     labels: ['Paid', 'Due'],
    //     datasets: [{
    //       label: 'Fees',
    //       data: [75, 25],
    //       backgroundColor: ['#28a745', '#dc3545'],
    //     }],
    //   },
    // });

    // Cleanup function to destroy charts when component unmounts
    return () => {
      if (attendanceChartRef.current) attendanceChartRef.current.destroy();
      if (resultsChartRef.current) resultsChartRef.current.destroy();
      if (feesChartRef.current) feesChartRef.current.destroy();
    };
  }, []);
  const handleResetPassword = async (e) => {
    e.preventDefault();

    


    try {
      const payload = {
        regid: student.regid,  // assuming `sessionUser` contains regid
        old_password: null,
        new_password: null,
        confirm_new_password: null
      };
      if (!sessionKey || sessionKey === 'null' || !sessionUser) {
        toast.error("Session key or user is missing. Please log in again.");
        return;
      }
      if (!API_PARENT_LINK) {
        toast.error("API endpoint not available");
        return;
      }
      const school_id = localStorage.getItem('school_id');
      const apiUrl = `${API_PARENT_LINK}Students/change_password?session_user=${sessionUser}&session_key=${sessionKey}&school_id=${school_id}&device_id=Web`;


      const response = await axios.post(apiUrl, payload);
      if (response.data.success) {
        
        toast.success('Password updated successfully!');
        
        
      } else {
        toast.error(response.data.message || 'Failed to update password.');
      }
    } catch (error) {
      toast.error('An error occurred while updating the password.');
    }
  };

  return (
    <div className="modal d-block" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>

      <div className="modal-dialog " style={{ maxWidth: '90%', height: '90vh' }}>
        <div className="modal-content" style={{ height: '100%' }}>
          <div className="modal-header d-flex justify-content-between align-items-center">
            {/* Title aligned to the left */}
            <h5 className="modal-title ">Overview for {student.regid}   </h5>

            {/* Close icon aligned to the right */}
            <button
              type="button"
              onClick={onClose}
              className="btn btn-link text-dark"
              style={{ padding: 0 }}
              aria-label="Close"
            >
              <i className="bi bi-x-lg" style={{ fontSize: '24px' }}></i>
            </button>
          </div>
          <div className="modal-body" style={{ overflowY: 'auto' }}>
            <div className="container ">
              <div className="">
                <div class="card mb-4" style={{ width: '100%' }}>
                  <div class="card-header p-2 pt-2">
                    <div
                      className="icon icon-lg bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute"
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '15px' }}>
                      <i className="bi bi-journal-bookmark" style={{ fontSize: '20px', color: 'white' }}></i>
                    </div>
                    <div className="text-end">
                      <button
                        className="btn btn-sm btn-outline-primary"
                        onClick={handleResetPassword} // Call the reset password function
                        style={{ fontSize: '0.875rem' }}
                      >
                        Reset Password
                      </button>
                    </div>


                  </div>
                  <hr class="dark horizontal my-0 " />
                  <div class="p-4 pt-1">
                    <div className="row mt-2" style={{ width: '100%' }}>
                      <div className="col-md-6">
                        <p><strong>Reg ID:</strong> {student.regid}</p>
                        <p><strong>Full Name:</strong> {student.first_name}   {student.last_name
                        }</p>
                        <p><strong>Date of Birth:</strong> {student.dob}</p>

                        <p><strong>Gender:</strong> {student.gender}</p>
                        <p><strong>Address:</strong> {student.address}</p>
                        <p><strong>Acadmic Year:</strong> {student.academic_year}</p>
                        <p><strong>Admission Fee:</strong> {student.admission_fee
                        }</p>
                        <p><strong>Blood Group:</strong> {student.blood_group}</p>
                        <p><strong>Religion:</strong> {student.religion}</p>



                      </div>
                      <div className="col-md-6">
                        <p><strong>Phone Number:</strong> {student.phone_number}</p>
                        <p><strong>Class:</strong> {student.class}</p>
                        <p><strong>Section:</strong> {student.section
                        }</p>
                        <p><strong>Roll No:</strong> {student.roll_no}</p>
                        <p><strong>Enrollment Date:</strong> {student.enrollment_date}</p>
                        <p><strong>Father Name:</strong> {student.father_name}</p>
                        <p><strong>Mother Name:</strong> {student.mother_name
                        }</p>
                        <p><strong>Aadhar Number:</strong> {student.aadhar}</p>
                        <p><strong>Emergency Contact:</strong> {student.emergency_contact}</p>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-4" >
                <div className="col-md-6" >
                  <div className="card" style={{ height: '300px', width: '100%' }}>
                    <div className="card-header">Attendance</div>
                    <div className="card-body" style={{ height: '150px', width: '100%' }}>
                      <canvas id="attendanceChart" style={{ maxHeight: '100%', width: '100%' }}></canvas>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card" style={{ height: '300px', width: '100%' }}>
                    <div className="card-header">Results</div>
                    <div className="card-body" style={{ height: '150px', width: '100%' }}>
                      <canvas id="resultsChart" style={{ maxHeight: '100%', width: '100%' }}></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-4 justify-content-center">
                <div className="col-md-4">
                  <div className="card" style={{ height: '500px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="card-header">Fees</div>
                    <div className="card-body" style={{ height: '150px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <canvas id="feesChart" style={{ maxHeight: '100%', width: '100%' }}></canvas>
                    </div>
                  </div>
                </div>


              </div>

            </div>
          </div>
          {/* <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ShowOverview;



 

