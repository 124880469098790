import React from "react";
import { Card, Button, Dropdown, Row, Col, Form, Table, Image } from "react-bootstrap";
import Select from 'react-select';

import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import resultstage from '../assets/gif/resultstage.webp'


import first from '../assets/gif/icons8-winner.gif'
import second from '../assets/gif/icons8-trophy.gif'
import third from '../assets/gif/icons8-gift.gif'

// Register Chart.js modules
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// Sample chart data

const chartData = {
    labels: [
        "Class 1", "Class 2", "Class 3", "Class 4", "Class 5",
        "Class 6", "Class 7", "Class 8", "Class 9", "Class 10"
    ],
    datasets: [
        {
            label: "Unit Test",
            data: [75, 80, 85, 70, 90, 75, 80, 85, 70, 90],
            borderColor: "#007bff",
            backgroundColor: "rgba(0,123,255,0.1)",
            tension: 0.4,
            fill: false,
        },
        {
            label: "Half Yearly",
            data: [80, 85, 75, 90, 95, 75, 80, 85, 70, 90],
            borderColor: "#28a745",
            backgroundColor: "rgba(40,167,69,0.1)",
            tension: 0.4,
            fill: false,
        },
        {
            label: "Final Exam",
            data: [85, 70, 80, 95, 88, 80, 95, 88, 75, 80],
            borderColor: "#dc3545",
            backgroundColor: "rgba(220,53,69,0.1)",
            tension: 0.4,
            fill: false,
        },

    ],
};

// Mock data for toppers
const toppers = [
    { name: "Student A", class: "Class 10", roll: 1, percentage: 95, img: first },
    { name: "Student B", class: "Class 9", roll: 2, percentage: 92, img: second },
    { name: "Student C", class: "Class 8", roll: 3, percentage: 89, img: third },
];

const classOptions = Array.from({ length: 10 }, (_, i) => ({
    value: `Class ${i + 1}`,
    label: `Class ${i + 1}`,
}));


const customStyles = {
    control: (base, state) => ({
        ...base,
        width: '140px',

        borderRadius: '8px',
        padding: '0px',
        borderColor: state.isFocused ? 'gray' : '#4a90e2',
        boxShadow: state.isFocused ? '0 0 5px rgba(128, 128, 128, 0.5)' : 'none',
    }),
    menu: (base) => ({
        ...base,
        width: 140, // Dropdown width
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: 4, // Adjust padding around arrow icon
    }),
};


const ResultComponent = () => {
    const [selectedClass, setSelectedClass] = React.useState(classOptions[0]);
    return (
        <div className="container mt-2">
            {/* Toppers Card */}
            {/* Toppers Card */}
            <Card className="mb-4 p-3">
                <Row className="align-items-center">
                    {/* Left: Toppers Heading */}
                    <Col md={4} className="d-flex align-items-center">
                        <i className="bi bi-trophy-fill text-warning fs-3 me-2"></i>
                        <h4 className="mb-0">Toppers</h4>
                    </Col>

                    {/* Right: Add Result, Class Dropdown */}
                    <Col md={8} className="d-flex justify-content-end align-items-center">
                        <Button variant="primary" className="me-3 mt-3">
                            <i className="bi bi-plus-circle me-1"></i>Add Result
                        </Button>

                        <Select
                            className="shadow-sm rouded"
                            options={classOptions}
                            value={selectedClass}
                            onChange={(selected) => setSelectedClass(selected)}
                            styles={customStyles}
                        //components={{ IndicatorSeparator: () => null }} // Remove separator
                        />
                    </Col>
                </Row>

                {/* Toppers List */}
                <Row className="mt-3">
                    {toppers.map((topper, index) => (
                        <Col key={index} md={4} className="d-flex align-items-center mb-3">
                            {/* Left: Topper Image */}
                            <Image src={topper.img} width="40" height="40" className="me-3" />
                            {/* Right: Topper Info */}
                            <div>
                                <h6 className="mb-1">{`${index + 1}st Place - ${topper.name}`}</h6>
                                <p className="mb-0">{`Class: ${topper.class}`}</p>
                                <p className="mb-0">{`Roll No: ${topper.roll}`}</p>
                                <p className="mb-0">{`Percentage: ${topper.percentage}%`}</p>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Card>


            {/* Overview & Best Class Cards */}
            <Row className="mb-4">
                <Col md={9} style={{ height: '380px' }}>
                    <Card className="p-2" style={{ height: '380px' }}>
                        {/* <h5 className="mb-3">Overview of Results</h5> */}
                        {/* <Line data={chartData}  style={{height:'250px', border:'2px solid black'}}/> */}
                        <div className="card-body">
                            <div className="d-flex align-items-center mb-2 mt-0">
                                <Image src={resultstage} width="80" height="50" className="me-3" />
                                <h5 className="card-title mb-0" style={{ color: 'black' }}>Result Overview</h5>
                            </div>
                            <div className="chart-wrapper" style={{ height: '300px' }}>
                                <Line
                                    data={chartData}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        plugins: {
                                            legend: {
                                                display: true,
                                                position: "top",
                                                labels: {
                                                    color: "black",
                                                    usePointStyle: true,
                                                    pointStyle: "circle",
                                                },
                                            },
                                            tooltip: {
                                                callbacks: {
                                                    label: function (context) {
                                                        let label = context.dataset.label || "";
                                                        if (label) {
                                                            label += `: ${context.raw}%`;
                                                        }
                                                        return label;
                                                    },
                                                },
                                            },
                                        },
                                        scales: {
                                            x: {
                                                title: { display: true, color: "black" },
                                                grid: { display: false },
                                                ticks: { color: "black" },
                                            },
                                            y: {
                                                title: { display: true, text: "Result (%)", color: "black" },
                                                min: 0,
                                                max: 100,
                                                ticks: { stepSize: 10, color: "black" },
                                                grid: { display: false },
                                            },
                                        },
                                    }}
                                    height={300}
                                />
                            </div >
                        </div>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="p-3"  style={{ height: '380px' }}>
                        {/* Centered Heading with Icon */}
                        <div className="text-center mb-3">
                            <h5 className="d-inline">
                                <i className="bi bi-award me-2"></i> Best Class
                            </h5>
                        </div>

                        {/* Left-aligned Subheadings and Data */}
                        <div className="mb-3 ">
                            <p className="mb-1 text-bold" style={{ color: 'black' }}>
                                Unit Test:
                            </p>
                            <p className="mb-1" style={{ color: 'green' }}>
                                Class 2
                            </p>
                            <p className="mb-1" style={{ color: 'blue' }}>
                                Percentage: 92%
                            </p>
                        </div>

                        <div className="mb-3 ">
                            <p className="mb-1 text-bold" style={{ color: 'black' }}>
                                Half Yearly:
                            </p>
                            <p className="mb-1" style={{ color: 'green' }}>
                                Class 5
                            </p>
                            <p className="mb-1" style={{ color: 'blue' }}>
                                Percentage: 88%
                            </p>
                        </div>

                        <div className="mb-3 ">
                            <p className="mb-1 text-bold" style={{ color: 'black' }}>
                                Final Exam:
                            </p>
                            <p className="mb-1" style={{ color: 'green' }}>
                                Class 4
                            </p>
                            <p className="mb-1" style={{ color: 'blue' }}>
                                Percentage: 90%
                            </p>
                        </div>
                    </Card>
                </Col>
            </Row>

            {/* Student Search & Table */}
            <Card className="p-3">
                <Row className="align-items-center mb-3">
                    <Col md={8}>
                        <Form.Control type="text" placeholder="Search by name, class, percentage..." />
                    </Col>
                    <Col md={4} className="text-end">
                        <Form.Check inline label="All" name="examFilter" type="radio" defaultChecked />
                        <Form.Check inline label="Unit" name="examFilter" type="radio" />
                        <Form.Check inline label="Half Yearly" name="examFilter" type="radio" />
                        <Form.Check inline label="Final" name="examFilter" type="radio" />
                    </Col>
                </Row>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Class</th>
                            <th>Roll No</th>
                            <th>Percentage</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>John Doe</td>
                            <td>Class 1</td>
                            <td>12</td>
                            <td>89%</td>
                            <td><Button variant="outline-primary">Overview</Button></td>
                        </tr>
                        <tr>
                            <td>Jane Smith</td>
                            <td>Class 2</td>
                            <td>18</td>
                            <td>92%</td>
                            <td><Button variant="outline-primary">Overview</Button></td>
                        </tr>
                    </tbody>
                </Table>
            </Card>
        </div>
    );
};

export default ResultComponent;
