import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import moneyGif from '../assets/gif/icons8-money.gif'
import calendarGif from '../assets/gif/icons8-calendar.gif'
import profit from '../assets/gif/icons8-profit.gif'
import coin from '../assets/gif/icons8-stack-of-coins.gif'
import data from '../assets/data.json';



const FeesManagement = () => {



    const [students, setStudents] = useState([]);
    const [classSummary, setClassSummary] = useState([]);

    const [searchQuery, setSearchQuery] = useState('');
    const [filterStatus, setFilterStatus] = useState('all'); // 'all', 'green', 'yellow', 'orange', 'red'
    const [filteredStudents, setFilteredStudents] = useState(students);
    const [API_PARENT_LINK, setApiParent] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setApiParent(data.API_PARENT_LINK);
        // setApiParent2(data.API_PARENT_LINK);
    }, []);


    const [error, setError] = useState('');
    const fetchData = async () => {
        setError('');
        try {

            const sessionKey = encodeURIComponent(localStorage.getItem('session_key'));
            const sessionUser = localStorage.getItem('session_user');
            const school_id = localStorage.getItem('school_id');
            if (!sessionKey || sessionKey === 'null' || !sessionUser) {
                setError("Session key or user is missing. Please log in again.");
                return;
            }
            if (!API_PARENT_LINK) {
                throw new Error("API endpoint not available");
            }
            const apiUrl = `${API_PARENT_LINK}FeeManagement/fee_overview?session_key=${sessionKey}&session_user=${sessionUser}&school_id=${school_id}`;

            const response = await axios.get(apiUrl);
            //console.log(response);
            
            const { card_data, class_summary, students_data } = response.data;
            //console.log(class_summary);
            

            
            setFeesData({
                today: card_data.today,
                thisMonth: card_data.this_month,
                lastMonth: card_data.last_month,
                last6Months: card_data.last_six_months,
            });
            setClassSummary(class_summary);
            setStudents(students_data);
            setFilteredStudents(students_data);

        } catch (error) {
            console.error('Error fetching data: ', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [API_PARENT_LINK]);

    const [feesData, setFeesData] = useState({
        today: { cash: 0, online: 0 },
        thisMonth: { cash: 0, online: 0 },
        lastMonth: { cash: 0, online: 0 },
        last6Months: { cash: 0, online: 0 },
    });

    const handleSearch = (query) => setSearchQuery(query.toLowerCase());

    //Filter students by search query and color filter
    useEffect(() => {
        const filtered = students.filter((student) => {
            const matchesSearch = student.first_name.toLowerCase().includes(searchQuery)
            // ||student.last_name.toLowerCase().includes(searchQuery)
             || student.class.toLowerCase().includes(searchQuery);
            const matchesFilter =
                filterStatus === 'all' ||
                (filterStatus === 'green' && student.overall_status === 'paid') ||
                (filterStatus === 'yellow' && student.overall_status === 'unpaid') ||
                (filterStatus === 'red' && student.overall_status === 'defaulter');

            return matchesSearch && matchesFilter;
        });
        setFilteredStudents(filtered);
    }, [searchQuery, filterStatus, students]);

    
    

    // const chartData = {
    //     labels: ['Class 1', 'Class 2', 'Class 3', 'Class 4', 'Class 5', 'Class 6', 'Class 7', 'Class 8', 'Class 9', 'Class 10'],
    //     datasets: [
    //         {
    //             label: 'Fees Collected',
    //             backgroundColor: '#00c698', // Softer shade of green
    //             //borderColor: '#00796B', // Darker green for border
    //             data: [10000, 15000, 12000, 18000, 20000, 2000, 5000, 3000, 1000, 4000],
    //             borderWidth: 1,
    //             borderColor: "white",
    //             borderRadius: 5, // Rounded bars
    //             hoverBackgroundColor: '#00ad84',
    //             barThickness: 30,
    //         },
    //         {
    //             label: 'Pending Fees',
    //             backgroundColor: '#ff6b6b', // Softer shade of red
    //             //borderColor: '#C62828', // Darker red for border
    //             data: [2000, 5000, 3000, 1000, 4000, 10000, 15000, 12000, 18000, 20000],
    //             borderColor: "white",
    //             borderWidth: 1,
    //             borderRadius: 5, // Rounded bars
    //             hoverBackgroundColor: '#ff4747',
    //             barThickness: 30,
    //         }
    //     ]

    // };

    // const chartOptions = {
    //     responsive: true,
    //     plugins: {
    //         tooltip: {
    //             callbacks: {
    //                 label: function (context) {
    //                     const value = context.raw;
    //                     const dataset = context.dataset.label;
    //                     return `${dataset}: ₹${value}`;
    //                 }
    //             }
    //         }
    //     }
    // };
    // const chartOptions = {
    //     responsive: true,
    //     maintainAspectRatio: false,
    //     plugins: {
    //         tooltip: {
    //             callbacks: {
    //                 label: function (context) {
    //                     const value = context.raw;
    //                     const dataset = context.dataset.label;
    //                     return `${dataset}: ₹${value}`;
    //                 },
    //             },
    //         },
    //         legend: {
    //             labels: {
    //                 color: 'black', // All text in black
    //                 font: {
    //                     size: 14,
    //                     weight: 'bold',
    //                 },
    //             },
    //         },
    //     },
    //     scales: {
    //         x: {
    //             grid: {
    //                 display: false, // Disable grid lines
    //             },
    //             ticks: {
    //                 color: 'black', // X-axis labels in black
    //             },
    //         },
    //         y: {
    //             grid: {
    //                 display: false, // Disable grid lines
    //             },
    //             ticks: {
    //                 color: 'black', // Y-axis labels in black
    //             },
    //         },
    //     },
    // };

    const cardData = [
        { title: "Today's Collection", data: feesData.today, gif: coin },
        { title: "This Month", data: feesData.thisMonth, gif: moneyGif },
        { title: "Last Month", data: feesData.lastMonth, gif: profit },
        { title: "Last 6 Months", data: feesData.last6Months, gif: calendarGif },
    ];

    const formatMonth = (dateString) => {
        if (!dateString) return ''; // Handle empty or invalid data
        const [year, month] = dateString.split('-');
        const date = new Date(year, month - 1); // JS months are 0-indexed
        return new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date); // Returns 'Nov', 'Dec', etc.
    };
    const getStackedChartData = () => {
        const labels = classSummary.map(item => item.class);
        const paidData = classSummary.map(item => parseInt(item.paid, 10));
        const unpaidData = classSummary.map(item => parseInt(item.unpaid, 10));
        const defaulterData = classSummary.map(item => parseInt(item.defaulter, 10));

        return {
            labels,
            datasets: [
                {
                    label: 'Paid',
                    data: paidData,
                    backgroundColor: '#006600',
                    borderWidth: 1,
                },
                {
                    label: 'Unpaid',
                    data: unpaidData,
                    backgroundColor: '#ff9900',
                    borderWidth: 1,
                },
                {
                    label: 'Defaulter',
                    data: defaulterData,
                    backgroundColor: '#cc3333',
                    borderWidth: 1,
                },
            ],
        };
    };

    const stackedChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: {
                    color: 'black',
                    font: {
                        size: 14,
                    },
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const datasetLabel = context.dataset.label; // 'Paid Fees', 'Unpaid Fees', or 'Defaulter Fees'
                        const value = context.raw; // The actual value of the bar
                        const className = context.label; // Class name from the labels array
                        
                        return [`Class : ${className}`,`${datasetLabel}: ${value}`];
                    },                },
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
                ticks: {
                    color: 'black',
                },
                title: {  // Add X-axis title
                    display: true,
                    text: 'Class',
                    color: 'black',
                    font: {
                        size: 16,
                        weight: 'normal',
                    },
                },
            },
            y: {
                stacked: true,
                grid: {
                    display: true,
                    color: '#e0e0e0',
                },
                ticks: {
                    color: 'black',
                    stepSize: 10,
                },
                title: {  // Add Y-axis title
                    display: true,
                    text: 'Number of Students',
                    color: 'black',
                    font: {
                        size: 16,
                        weight: 'normal',
                    },
                },
            },
        },
    };
    

    return (
        <div className="container mt-2">
            {/* Cards Section */}
            {/* Cards Section */}
            <div className="row mb-4">
                {cardData.map((card, index) => (
                    <div className="col-md-3" key={index}>
                        <div className="card shadow-sm border-0">
                            <div className="card-body d-flex align-items-center">
                                <img
                                    src={card.gif}
                                    alt="Icon"
                                    className="me-3"
                                    style={{ width: '35px', height: '40px', objectFit: 'cover' }}
                                />
                                <div>
                                    <h5 className="card-title mb-3 text-black">{card.title}</h5>

                                    {index === 0 ? (
                                        <>
                                            <p className="card-text text-success">
                                                Cash: ₹{card.data.cash}
                                                <p className="card-text text-secondary">
                                                    Online: ₹{card.data.online}
                                                </p>
                                            </p>
                                            {/* <p className="card-text text-secondary">
                                                Yesterday: ₹{card.data.yesterday}
                                            </p> */}
                                        </>
                                    ) : (
                                        <>
                                            <p className="card-text text-success">
                                                Cash: ₹{card.data.cash}
                                                <p className="card-text text-danger">
                                                    Online: ₹{card.data.online}
                                                </p>
                                            </p>

                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>


            <div className="row mb-4 bg-white shadow-sm p-2 rounded">
                {/* Search and Filter Section */}
                <div className="row mb-0 bg-white m-2  ">
                    <div className="col-md-6">
                        <input
                            type="text"
                            className="form-control small-search-sm shadow-sm p-2"
                            placeholder="Search by name or class"
                            value={searchQuery}
                            onChange={(e) => handleSearch(e.target.value)}
                            style={{
                                border: '1px solid #dee1e3', // Default Bootstrap primary border color
                                borderRadius: '2rem', // Bootstrap rounded corners
                                fontSize: '0.9rem' // Smaller font size
                            }}
                            onFocus={(e) => (e.target.style.borderColor = 'royalblue')} // Change border color on focus
                            onBlur={(e) => (e.target.style.borderColor = '#dee1e3')}
                        />
                    </div>
                    <div className="col-md-6 d-flex justify-content-end align-items-center bg-white ">
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="filter"
                                value="all"
                                checked={filterStatus === 'all'}
                                onChange={() => setFilterStatus('all')}
                            />
                            <label className="form-check-label">All</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="filter"
                                value="green"
                                checked={filterStatus === 'green'}
                                onChange={() => setFilterStatus('green')}
                            />
                            <label className="form-check-label text-success">Paid</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="filter"
                                value="yellow"
                                checked={filterStatus === 'yellow'}
                                onChange={() => setFilterStatus('yellow')}
                            />
                            <label className="form-check-label text-warning">UnPaid</label>
                        </div>

                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="filter"
                                value="red"
                                checked={filterStatus === 'red'}
                                onChange={() => setFilterStatus('red')}
                            />
                            <label className="form-check-label text-danger">Defaulter</label>
                        </div>
                    </div>
                </div>

                {/* Students Table */}
                <div className="row mt-3">
                    <div className="col bg-white w-full">
                        <div
                            className="table-responsive pb-2"
                            style={{ height: '300px', overflowY: 'auto', backgroundColor: 'white', borderRadius: '5px' }}
                        >
                            <table className="table table-bordered table-hover mb-0">
                                <thead style={{ position: 'sticky', top: -1, backgroundColor: '#f8f9fa',alignItems:'left' }}>
                                    <tr >
                                        <th style={{ color: 'black' }}>Reg ID</th>
                                        <th style={{ color: 'black' }}>Name</th>
                                        <th style={{ color: 'black' }}>Class</th>
                                        <th style={{ color: 'black' }}>Last Payment Month</th>
                                        <th style={{ color: 'black' }}>Last Payment Date</th>
                                        
                                        <th style={{ color: 'black' }}>Fees Paid</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredStudents.map((student, index) => (
                                        <tr key={index}>
                                            <td>{student.regid}</td>
                                            <td>{student.first_name}{' '}{student.last_name}</td>
                                            <td>{student.class}-{student.section}</td>
                                            <td style={{textAlign:'center'}}>{formatMonth(student.last_paid_month)}</td>
                                            <td>{student.last_payment_date}</td>
                                            <td
                                                className={`text-white text-center ${student.overall_status === 'paid'
                                                    ? 'bg-success'
                                                    : student.overall_status === "unpaid"
                                                        ? 'bg-warning'
                                                        : student.overall_status === "defaulter"
                                                            ? 'bg-danger'
                                                            : 'bg-secondary'
                                                    }`}

                                            >
                                                {student.overall_status}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>


            {/* Bar Chart Section */}
            <div className="row mt-4 ">
                <div className="col-md-12 m-0 p-0">
                    <div className="card shadow-sm border-0  " >
                        <div className="card-body ">
                            <h2 className="card-title" style={{ color: 'black' }}> Students Fees </h2>
                            {/* <select
                                        className="form-select mb-2"
                                        value={filterStatus}
                                        onChange={(e) => setFilterStatus(e.target.value)}
                                    >
                                        <option value="this month">This Month</option>
                                        <option value="last month">Last Month</option>
                                        <option value="this year">This Year</option>
                                    </select> */}

                            <div className="chart-wrapper  " style={{ height: '350px', width: '100%', position: 'relative' }}>



                            <Bar data={getStackedChartData()} options={stackedChartOptions} style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0, width: '100%', height: '100%',
                                }} />

                            </div>



                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default FeesManagement;
