import React , { useEffect }from 'react'
import '../assets/css/material-dashboard.css';
import '../assets/css/material-dashboard.css.map';
import '../assets/css/material-dashboard.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/main.css'
import { Chart, registerables,LinearScale } from 'chart.js';
Chart.register(...registerables, LinearScale); 
const Overview = () => {
  useEffect(() => {
    const ctxBar = document.getElementById('chart-bars').getContext('2d');
    const ctxLine = document.getElementById('chart-line').getContext('2d');
    const ctxTasks = document.getElementById('chart-line-tasks').getContext('2d');

    // Function to create a chart
    const createChart = (ctx, type, data, options) => {
      // Destroy existing chart if it exists
      if (ctx.chart) {
        ctx.chart.destroy();
      }
      ctx.chart = new Chart(ctx, {
        type: type,
        data: data,
        options: options,
      });
    };

    // Data and options for the bar chart
    const barData = {
      labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      datasets: [{
        label: 'Website Views',
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: 'White',
        //borderColor: 'rgba(75, 192, 192, 1)',
        //borderWidth: 1,
        barThickness: 10,
        borderRadius: 10,
      }],
    };
    const barOptions = {
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            display: false, // Disable grid lines
          },
          ticks: {
            display: true,
            color: 'white', // Hide y-axis labels
          },
        },
        x: {
          grid: {
            display: false, // Disable grid lines on the x-axis
          },
          ticks: {
            display: true, // Show x-axis labels
            color: 'white',
          },
        },
        
        
      },
      plugins: {
        legend: {
          display: false, // Hide the legend
        },
        tooltip: {
          enabled: true, // Enable tooltips on hover
          callbacks: {
            label: function (context) {
              const index = context.dataIndex; // Get the index of the bar being hovered
              const teacherData = [3, 30, 70, 66, 55, 88, 22][index]; // Your teacher data
              const studentData = [33, 44, 55, 66, 77, 88, 22][index]; // Your student data
              const staffData = [4, 5, 3, 2, 7, 8, 4][index]; // Your staff data
              
              return [
                `Teachers: ${teacherData}`,
                `Students: ${studentData}`,
                `Staff: ${staffData}`,
              ];
            },
          },},
      },
    };

    // Create the bar chart
    createChart(ctxBar, 'bar', barData, barOptions);

    // Data and options for the line chart
    const lineData = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug','Sep','Oct','Nov','Dec'],
      datasets: [{
        
        data: [30, 50, 80, 70, 100, 90, 110,100,87,99,45,90],
        fill: false,
        borderColor: 'White',
        tension: 0.1,
        
      }],
    };
    const lineOptions = {
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            display: false, // Disable grid lines
          },
          ticks: {
            display: true,
            color: 'white', // Hide y-axis labels
          },
        },
        x: {
          grid: {
            display: false, // Disable grid lines on the x-axis
          },
          ticks: {
            display: true, // Show x-axis labels
            color: 'white',
          },
        },
        
        
      },
      plugins: {
        legend: {
          display: false, // Hide the legend
        },
        tooltip: {
          enabled: true, // Enable tooltips
          backgroundColor: 'rgba(0, 0, 0, 0.7)', // Set background color for the tooltip
          titleColor: 'white', // Set title color of the tooltip
          bodyColor: 'white', // Set body color of the tooltip
          callbacks: {
            title: (tooltipItems) => {
              // Return the label for the tooltip title
              return tooltipItems[0].label; // Use the label from the x-axis
            },
            label: (tooltipItem) => {
              // Return the data value for the tooltip body
              return `Value: ${tooltipItem.raw}`; // Use the raw data value
            },
          },
        },
      },
    };
    

    // Create the line chart
    createChart(ctxLine, 'line', lineData, lineOptions);

    // Data and options for the tasks chart
    const tasksData = {
      labels: ['Task 1', 'Task 2', 'Task 3'],
      datasets: [{
        label: 'Completed Tasks',
        data: [30, 20, 50],
        fill: false,
        borderColor: 'rgba(54, 162, 235, 1)',
        tension: 0.1,
      }],
    };
    const tasksOptions = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    // Create the tasks chart
    createChart(ctxTasks, 'line', tasksData, tasksOptions);

    // Clean up function to destroy charts on unmount
    return () => {
      if (ctxBar.chart) ctxBar.chart.destroy();
      if (ctxLine.chart) ctxLine.chart.destroy();
      if (ctxTasks.chart) ctxTasks.chart.destroy();
    };
  }, []);

  return (
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-header p-3 pt-2">
              <div 
                className="icon icon-lg bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute"
                style={{ width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '15px' }}>
                <i className="bi bi-journal-bookmark" style={{ fontSize: '30px', color: 'white' }}></i>
              </div>

              <div class="text-end pt-1">
                <p class="text-sm mb-0 text-capitalize">Teacher</p>
                <h4 class="mb-0">53</h4>
              </div>
            </div>
            <hr class="dark horizontal my-0" />
            <div class="card-footer p-3">
              <p class="mb-0 text-xs"><span class="text-success text-sm font-weight-bolder">55  </span>Present</p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-header p-3 pt-2">
              <div 
                className="icon icon-lg bg-gradient-primary shadow-primary  text-center border-radius-xl mt-n4 position-absolute"
                style={{ width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '15px' }}>
                <i className="bi bi-file-person" style={{ fontSize: '30px', color: 'white' }}></i>
              </div>
              <div class="text-end pt-1">
                <p class="text-sm mb-0 text-capitalize">Student</p>
                <h4 class="mb-0">2,300</h4>
              </div>
            </div>
            <hr class="dark horizontal my-0" />
            <div class="card-footer p-3">
              <p class="mb-0 text-xs"><span class="text-success text-sm font-weight-bolder">2000 </span>Present</p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-header p-3 pt-2">
              <div 
                className="icon icon-lg bg-gradient-secondary shadow-secondary text-center border-radius-xl mt-n4 position-absolute"
                style={{ width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '15px' }}>
                <i className="bi bi-buildings-fill" style={{ fontSize: '30px', color: 'white' }}></i>
              </div>
              <div class="text-end pt-1">
                <p class="text-sm mb-0 text-capitalize">Classroom</p>
                <h4 class="mb-0">100</h4>
              </div>
            </div>
            <hr class="dark horizontal my-0" />
            <div class="card-footer p-3">
              <p class="mb-0 text-xs"><span class="text-success text-sm font-weight-bolder">88</span> Available</p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6">
          <div class="card">
            <div class="card-header p-3 pt-2">
            <  div 
                className="icon icon-lg bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute"
                style={{ width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '15px' }}>
                <i className="bi bi-people-fill" style={{ fontSize: '30px', color: 'white' }}></i>
              </div>
              <div class="text-end pt-1">
                <p class="text-sm mb-0 text-capitalize">Staff</p>
                <h4 class="mb-0">20</h4>
              </div>
            </div>
            <hr class="dark horizontal my-0" />
            <div class="card-footer p-3">
              <p class="mb-0 text-xs"><span class="text-success text-sm font-weight-bolder">10 </span>Present</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-4 col-md-6 mt-4 mb-4">
          <div class="card z-index-2 ">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
              <div class="bg-gradient-primary shadow-primary border-radius-lg py-3 pe-1">
                <div class="chart">
                  <canvas id="chart-bars" class="chart-canvas" height="170"></canvas>
                </div>
              </div>
            </div>
            <div class="card-body">
              <h6 class="mb-0 ">Website Views</h6>
              <p class="text-sm ">Last Campaign Performance</p>
              <hr class="dark horizontal" />
              <div class="d-flex ">
                <i class="material-icons text-sm my-auto me-1">schedule</i>
                <p class="mb-0 text-sm"> campaign sent 2 days ago </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mt-4 mb-4">
          <div class="card z-index-2  ">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
              <div class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1">
                <div class="chart">
                  <canvas id="chart-line" class="chart-canvas" height="170"></canvas>
                </div>
              </div>
            </div>
            <div class="card-body">
              <h6 class="mb-0 "> Daily Sales </h6>
              <p class="text-sm "> (<span class="font-weight-bolder">+15%</span>) increase in today sales. </p>
              <hr class="dark horizontal" />
              <div class="d-flex ">
                <i class="material-icons text-sm my-auto me-1">schedule</i>
                <p class="mb-0 text-sm"> updated 4 min ago </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-4 mb-3">
          <div class="card z-index-2 ">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
              <div class="bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1">
                <div class="chart">
                  <canvas id="chart-line-tasks" class="chart-canvas" height="170"></canvas>
                </div>
              </div>
            </div>
            <div class="card-body">
              <h6 class="mb-0 ">Completed Tasks</h6>
              <p class="text-sm ">Last Campaign Performance</p>
              <hr class="dark horizontal" />
              <div class="d-flex ">
                <i class="material-icons text-sm my-auto me-1">schedule</i>
                <p class="mb-0 text-sm">just updated</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Overview
